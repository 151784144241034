import React from "react";
import { CertEnrollmentRequiredGroupScore } from "CORE/entities/CertificationEnrollment";
import { Grid, Typography, Paper, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import DataService from "CORE/data-service/DataService";
import BaseComponent from "CORE/base-classes/BaseComponent";
import { IProps, IState } from "CORE/interfaces/main-interfaces";
import { CheckCircleOutline, ErrorOutline } from '@material-ui/icons';

interface IEnrollmentScoreProps extends IProps {
    enrollmentId: number;
}

interface IEnrollmentScoreState extends IState {
    enrollmentScore: CertEnrollmentRequiredGroupScore[];
}

export default class CertEnrollmentScore extends BaseComponent<IEnrollmentScoreProps, IEnrollmentScoreState>{
    private enrollmentScoreDataSvc: DataService<CertEnrollmentRequiredGroupScore> = new DataService('certificationEnrollments/requiredScore');

    constructor(props: IEnrollmentScoreProps) {
        super(props);

    }

    componentDidMount() {
        this.getEnrollScoreData();
    }

    getInitialState() {
        return {
            enrollmentScore: []
        } as IEnrollmentScoreState;
    }

    getEnrollScoreData() {
        this.showLoading(true);
        this.enrollmentScoreDataSvc.query({ filter: `enrollmentId eq ${this.props.enrollmentId}`, order: 'group/index asc, group/name asc' }).then((res) => {
            if (res.length > 0) {
                this.setState({ enrollmentScore: res })
            }
        }).finally(() => {
            this.showLoading(false)
        });
    }

    render() {
        return (
            <div className="enrollment-score-wrapper">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" gutterBottom>Current Enrollment Score</Typography>
                        {this.state.enrollmentScore && this.state.enrollmentScore.length > 0
                            ? <Paper>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Group Name</TableCell>
                                            <TableCell align="right">Actual Score</TableCell>
                                            <TableCell align="right">Required Score</TableCell>
                                            <TableCell align="right">Passes</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.enrollmentScore.map((el: CertEnrollmentRequiredGroupScore, i: number) => (
                                            <TableRow key={i}>
                                                <TableCell>{el.scoreName}</TableCell>
                                                <TableCell align="right">{el.score}</TableCell>
                                                <TableCell align="right">{el.requiredScore}</TableCell>
                                                <TableCell align="right">{el.score >= el.requiredScore ? <CheckCircleOutline className="action-green"></CheckCircleOutline> : <ErrorOutline className="action-grey"></ErrorOutline>}</TableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow className="summary-row">
                                            <TableCell align="left"><b>Total</b></TableCell>
                                            <TableCell align="right"><b>{this.state.enrollmentScore.reduce<number>((sum, obj) => sum + obj.score, 0)}</b></TableCell>
                                            <TableCell align="right"><b>{this.state.enrollmentScore.reduce<number>((sum, obj) => sum + obj.requiredScore, 0)}</b></TableCell>
                                            <TableCell align="right">{this.state.enrollmentScore.every((el) => el.score >= el.requiredScore) ? <CheckCircleOutline className="action-green"></CheckCircleOutline> : <ErrorOutline className="action-grey"></ErrorOutline>}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Paper> :
                            <Paper className="padding-10">
                                No data for this enrollment.
                            </Paper>}
                    </Grid>
                </Grid>
            </div>
        )
    }
}