import React from "react";
import { Link } from "react-router-dom"
import { Container, Paper, Grid, Button, Typography, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import "../../App.css";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

import { Auth } from "CORE/utils/Auth";
import { IState, IProps } from "CORE/interfaces/main-interfaces";
import BaseComponent from "CORE/base-classes/BaseComponent";
import { Company } from "../admin/companies.component";
import VideoPresentation from "./video-presentation";
import videoTruTech from '../../../assets/presentation.mp4';


class ILoginProps extends IProps {
    login: () => void;
    history: any;
    location: any;
}

class LoginState extends IState {
    companyId: number;
    authUser: {
        Email: string
        password: string
    };
}


export default class LoginComponent extends BaseComponent<ILoginProps, LoginState> {
    availableCompanies: Company[] = [];

    constructor(props: ILoginProps) {
        super(props);
    }
    video: any;
    canvas: any;

    getInitialState() {
        return {
            companyId: 0,
            authUser: {
                Email: '',
                password: ''
            },
        }
    }


    componentDidMount() {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.authUser.password) {
                return false;
            }
            return true;
        });
    }


    handleChange = (name: string) => (event: any) => {
        this.setState({
            authUser: {
                ...this.state.authUser,
                [name]: event.target.value
            }
        })
    }


    login = (e: any) => {
        e.preventDefault();
        
        this.showLoading(true);
        Auth.getProvider().signIn(e.target["email"].value as string, e.target["repeatPassword"].value as string, this.state.companyId)
            .then(
                (user: any) => {
                    if (user.length > 1) {
                        this.notifyWarning('This user is assigned to multiple companies. Please select company to log in.');
                        user.map((el: any) => {
                            let company = new Company();
                            company.name = el.name;
                            company.id = el.users[0].companyId;
                            this.availableCompanies.push(company);
                        })
                    } else {
                        //this.playVideo();
                        this.completeLogin();
                    }
                },
                () => {
                    this.notifyError('Wrong login or password');
                }
            ).finally(() => { this.showLoading(false) });
    }

    playVideo = () => { };


    completeLogin = (): void => {
        if (this.props.location && this.props.location.state && this.props.location.state.from) {
            this.props.history.push(this.props.location.state.from.pathname);
        } else {
            this.props.history.push("/");
        }
    }

    render() {
        return (
            <div>
                <VideoPresentation
                    play={(f) => this.playVideo = f}
                    ended={() => this.completeLogin()}
                />

                <div className="login-form">
                    <Container maxWidth="sm">
                        <ValidatorForm
                            ref="form"
                            onSubmit={(e: any) => this.login(e)}
                        >
                            <Paper elevation={3} style={{ padding: 20 }}>
                                <Typography color="textSecondary" variant="h6" component="h4" align="center">
                                    Login
                          </Typography>
                                <TextValidator
                                    style={{ width: '100%' }}
                                    label="Email"
                                    name="email"
                                    type="email"
                                    margin="normal"
                                    value={this.state.authUser.Email || ""}
                                    onChange={this.handleChange('Email')}
                                    validators={['required', 'isEmail']}
                                    errorMessages={['this field is required', 'email is not valid']}
                                />
                                <TextValidator
                                    id="standard-number"
                                    type="password"
                                    style={{ width: '100%' }}
                                    label="Password"
                                    name="repeatPassword"
                                    margin="normal"
                                    value={this.state.authUser.password || ""}
                                    onChange={this.handleChange('password')}
                                    validators={['required', 'isPasswordMatch']}
                                    errorMessages={['this field is required', 'password mismatch']}
                                />
                                {
                                    this.availableCompanies.length > 0 ?
                                        <FormControl style={{ width: '100%' }} className="MuiFormControl-marginNormal">
                                            <InputLabel htmlFor="login-companies">Companies</InputLabel>
                                            <Select className="login-companies"
                                                value={this.state.companyId || 0}
                                                onChange={e => { this.setState({ companyId: e.target.value as number }) }}
                                            >{this.availableCompanies.map((el: Company, i: number) => <MenuItem key={i} value={el.id}>{el.name}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                        : null
                                }
                                <Link className="forgot-pass-link" to={"/login/resetpassword"}>Forgot Password?</Link>
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-end"
                                    alignContent="flex-end"
                                >
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        style={{ marginTop: 20 }}
                                    >
                                        Login
                                 </Button>
                                </Grid>
                            </Paper>
                        </ValidatorForm>
                    </Container>
                </div >
            </div >
        );
    }
}