import React from "react";
import { CertSetLevelEnrollModel } from "CORE/entities/CertificationEnrollment";
import { Grid, Paper, Table, TableHead, TableCell, TableRow, TableBody, FormControl, MenuItem, Select, Checkbox } from "@material-ui/core";
import { UserDetails } from "./users.component";
import Certificate from 'CORE/entities/Certificate';
import { CertificateRow } from 'CORE/entities/Certificate';
import UserCertificateStatus from "CORE/entities/UserCertificateStatus";
import BaseComponent from "CORE/base-classes/BaseComponent";
import { forkJoin } from 'rxjs';
import { IProps, IState } from "CORE/interfaces/main-interfaces";
import ResourceDataService from "CORE/data-service/ResourceDataService";

interface ISetUserCertLevelProps extends IProps {
    userToSetLevel: UserDetails;
    mode: Mode;
    cleanCache: boolean;
    disabled?: boolean;
    cleanCacheFinished: () => void;
    showConfirmation?: (show: boolean) => void;
    onChange: (certs: number[]) => void;
}

export enum Mode {
    View,
    Edit,
    Create
}

interface ISetUserCertLevelState extends IState {
    setLevelCertificates: CertificateRow[],
    certificatesLookup: Certificate[];
    userCertificateStatus: UserCertificateStatus[];
}

export default class SetUserLevel extends BaseComponent<ISetUserCertLevelProps, ISetUserCertLevelState>{
    private lookupCertificates: ResourceDataService<Certificate> = new ResourceDataService('certifications/certificates/order');
    private lookupUserCertStatuses: ResourceDataService<UserCertificateStatus> = new ResourceDataService('certifications/certificates/userStatuses');
    certSetLevelModel: CertSetLevelEnrollModel;

    constructor(props: ISetUserCertLevelProps) {
        super(props);
    }

    componentDidMount() {
        if (this.props.cleanCache)
            this.clearCache();
        if (this.props.mode == Mode.Create) {
            this.getCertOnlyLookup();
        }
        else {
            this.getLookupData();
        }
    }

    clearCache() {
        this.lookupCertificates.clearChache();
        this.lookupUserCertStatuses.clearChache();
        this.props.cleanCacheFinished();
    }

    getInitialState() {
        return {
            data: [],
            setLevelCertificates: [],
            certificatesLookup: [],
            userCertificateStatus: []
        } as ISetUserCertLevelState;
    }

    getLookupData(useCache: boolean = true) {
        this.showLoading(true);
        if (!useCache)
            this.lookupUserCertStatuses.clearChache();
        forkJoin(
            this.lookupUserCertStatuses.query({ filter: `userId eq ${this.props.userToSetLevel.id} and isCurrenLevel eq true`, order: 'direction asc' }),
            this.lookupCertificates.query({ filter: 'isActive eq true', order: 'direction,certificateChainIndex', oDataQuery: '&$select=id,name,direction,certificateChainIndex' }))
            .subscribe(([userCertStatuses, certificates]) => {
                this.setState({ userCertificateStatus: userCertStatuses });
                this.setState({ certificatesLookup: certificates });
                let setLevelCertificates: CertificateRow[] = [];
                let directions = [...new Set(certificates.map(x => x.direction))];
                for (let i = 0; i < directions.length; i++) {
                    let setLevelCertItem = new CertificateRow();
                    setLevelCertItem.index = i;
                    setLevelCertItem.direction = directions[i];
                    if (userCertStatuses.filter(x => x.direction == directions[i]).length > 0) {
                        setLevelCertItem.isActive = setLevelCertItem.initialValueIsActive = true;
                        setLevelCertItem.id = setLevelCertItem.initialValueId = userCertStatuses.filter(x => x.direction == directions[i])[0].certificateId as number;
                    }
                    setLevelCertificates.push(setLevelCertItem);
                }
                this.setState({ setLevelCertificates: setLevelCertificates });
                this.props.onChange(setLevelCertificates.filter(x => x.isActive).map(x => x.id));
            }, () => { }, () => {
                this.showLoading(false)
            });
    }

    getCertOnlyLookup() {
        this.showLoading(true);
        //ToDo: change order
        this.lookupCertificates.query({ filter: 'isActive eq true', order: 'direction,certificateChainIndex', oDataQuery: '&$select=id,name,direction,certificateChainIndex' }).then((certificates: Certificate[]) => {
            this.setState({ certificatesLookup: certificates });
            let setLevelCertificates: CertificateRow[] = [];
            let directions = [...new Set(certificates.map(x => x.direction))];
            for (let i = 0; i < directions.length; i++) {
                let setLevelCertItem = new CertificateRow();
                setLevelCertItem.index = i;
                setLevelCertItem.direction = directions[i];
                setLevelCertificates.push(setLevelCertItem);
                setLevelCertItem.initialValueId = 0;
                setLevelCertItem.initialValueIsActive = false;
            }
            this.setState({ setLevelCertificates: setLevelCertificates });
        }).finally(() => {
            this.showLoading(false)
        });
    }

    checkForCertChanges() {
        let currentLevelChanged = false;
        this.state.setLevelCertificates.forEach(el => {
            if (el.id != el.initialValueId || el.isActive != el.initialValueIsActive)
            currentLevelChanged = true;                
        })
        this.props.showConfirmation(currentLevelChanged);
    }

    handleActiveDirections(checked: boolean, el: CertificateRow) {
        let setLevelCertificates = [...this.state.setLevelCertificates];
        setLevelCertificates.filter(x => x.direction == el.direction)[0].isActive = checked;
        this.setState({ setLevelCertificates: setLevelCertificates });
        this.props.onChange(setLevelCertificates.filter(x => x.isActive).map(x => x.id));
        this.checkForCertChanges();
    }

    changeCertificates = (certId: number, el: CertificateRow) => {
        let setLevelCertificates = [...this.state.setLevelCertificates];
        if (setLevelCertificates.filter(x => x.direction == el.direction)[0]) {
            setLevelCertificates.filter(x => x.direction == el.direction)[0].id = certId;
            this.setState({ setLevelCertificates: setLevelCertificates });
            this.props.onChange(setLevelCertificates.filter(x => x.isActive).map(x => x.id));
            this.checkForCertChanges();
        }
    }

    render() {
        return (
            <form autoComplete="off">
                <div className="user-level-wrapper">
                    <Grid container spacing={3}>
                        < Grid item xs={12}>
                            {this.props.mode == Mode.View ?
                                <React.Fragment>
                                    {this.state.userCertificateStatus && this.state.userCertificateStatus.length > 0
                                        ? <Paper>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Direction</TableCell>
                                                        <TableCell>Tech Level</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.userCertificateStatus.map((el: UserCertificateStatus, i: number) => (
                                                        <TableRow key={i}>
                                                            <TableCell>{el.direction}</TableCell>
                                                            <TableCell>{el.name}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </Paper> :
                                        <Paper className="padding-10">
                                            No data for this user.
                                </Paper>}</React.Fragment> :
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell>Direction</TableCell>
                                            <TableCell>Tech Level</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.setLevelCertificates.map((el: CertificateRow, i: number) => (
                                            <TableRow key={i}>
                                                <TableCell>
                                                    <Checkbox
                                                        checked={this.state.setLevelCertificates[i].isActive || false}
                                                        onChange={(event: any) => this.handleActiveDirections(event.target.checked, el)}
                                                        value="checkedB"
                                                        color="primary"
                                                        disabled={this.props.disabled}
                                                    />
                                                </TableCell>
                                                <TableCell>{el.direction}</TableCell>
                                                <TableCell>
                                                    <FormControl>
                                                        <Select
                                                            value={this.state.setLevelCertificates[i].id || 0}
                                                            onChange={(e) => { this.changeCertificates(e.target.value as number, el); }}
                                                            disabled={this.props.disabled || !this.state.setLevelCertificates[i].isActive}
                                                        >
                                                            {this.state.certificatesLookup && this.state.certificatesLookup.length > 0
                                                                ? this.state.certificatesLookup.filter(x => x.direction == el.direction).map((el: any, i: number) => <MenuItem key={i} value={el.id}>{el.name}</MenuItem>)
                                                                : <MenuItem value="" disabled>There are no available certificates</MenuItem>}
                                                        </Select>
                                                    </FormControl>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>}
                        </Grid>
                    </Grid>
                </div>
            </form >
        )
    }
}