import React from 'react';
import './App.css';
import { Switch, Route, Redirect } from 'react-router-dom';

import LoginComponent from './components/auth/login-component';
import MainComponent from './components/main-component';

import { PrivateRoute } from './components/auth/private-route';
import { UserRole } from 'CORE/entities/User';
import AppWrapper from './components/auth/AuthGuard';
import EnrollmentList from './components/enrollments-list';
import { IState, IProps } from 'CORE/interfaces/main-interfaces';
import EnrollmentForm from './components/enrollment-form';
import ResetPassComponent from './components/auth/reset-password';
import { createMuiTheme } from '@material-ui/core';

import Logo from '../assets/TrackerLogo.png';
import AdminComponent from './components/admin/admin.component';

interface IAppState extends IState {
}

interface IAppProps extends IProps {
}

const theme = createMuiTheme({
  typography: {
    "fontFamily": "\"Open Sans\", \"Helvetica\", \"Arial\", sans-serif",
  },
  palette: {

    type: 'light',
    // type: 'dark',

    primary: {

      main: '#358878',
      dark: '#3fb59e', // on hover
      // contrastText: '#fff',
    },
    // primary: green,
    // secondary: green,
    secondary: {
      light: '#25b97d',
      main: '#25b97d',
      dark: '#25b97d',
      contrastText: '#000',
    },
  },
});

export default class TrackerApp extends React.Component<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props);

    this.state = {
    } as IAppState
  }

  render() {
    return (
      <AppWrapper theme={theme} title="TruTech Tracker" logo={Logo}>
        <Switch>
          <Route exact path='/' render={() => (<Redirect to="/enrollments" />)} />
          <Route exact path="/login" component={LoginComponent} />
          <Route path="/login/resetpassword" component={ResetPassComponent} />
          <PrivateRoute path="/admin" roles={UserRole.CompanyUser} component={AdminComponent} />
          <PrivateRoute exact path="/enrollments" component={EnrollmentList} />
          <PrivateRoute exact path="/enrollment/:certId" component={EnrollmentForm} />
          <PrivateRoute path="/enrollment/:certId/:enrollId" component={MainComponent} />
        </Switch>
      </AppWrapper>
    );
  }
}
