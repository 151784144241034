import React from 'react';
import ReactDOM from 'react-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import TrackerApp from './TrackerApp';


function APP() {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <TrackerApp />
    </MuiPickersUtilsProvider>
  );
}

ReactDOM.render(<APP />, document.getElementById('root'));