import * as React from 'react';
import { Grid, TextField, InputAdornment, FormControlLabel, Switch, Button, Card, CardContent, Typography, CardActions, Container, FormControl, InputLabel, Select, Input, MenuItem, Chip } from '@material-ui/core';
import { KeyboardDatePicker, DatePicker } from '@material-ui/pickers';
import BaseComponent from 'CORE/base-classes/BaseComponent';
import { IProps, IState } from 'CORE/interfaces/main-interfaces';
import DataService from 'CORE/data-service/DataService';
import { CertEnrolments } from './admin/cert-enrolments.component';
import { User, UserRole } from 'CORE/entities/User';


interface ITechnicianProps extends IProps {
    levelName: string;
    certificateId: number;
    enrollmentId: number;
}

interface ITechnicianState extends IState {
    data: CertEnrolments;
    user: User;
    scores: any[];
    supervisions: User[]
}


export default class Technician extends BaseComponent<ITechnicianProps, ITechnicianState> {
    private certEnrollmentdataSvc: DataService<CertEnrolments> = new DataService('certificationEnrollments');
    private approveDataSvc: DataService<CertEnrolments> = new DataService('certificationEnrollments/approve');
    private scoreSvc: DataService<CertEnrolments> = new DataService('certificationEnrollments/requiredScore');
    private userSvc: DataService<User> = new DataService('users');


    constructor(props: ITechnicianProps) {
        super(props)
    }

    getInitialState() {
        return {
            data: new CertEnrolments(),
            user: {},
            scores: [],
            supervisions: [],
        } as ITechnicianState;
    }


    componentDidMount() {
        if (this.props.enrollmentId && this.props.certificateId) {
            this.getData();
            this.getScore();
            this.getSupervisors();
        }
    }

    getSupervisors = (): void => {
        this.showLoading(true);
        this.userSvc.query({ oDataQuery: `$filter= role eq '50' or role eq '100'` })
            .then(res => {
                this.setState({ supervisions: res })
                this.showLoading(false);
            })
            .catch(err => this.handleError(err))
    }

    getScore = (): any => {
        this.showLoading(true);
        this.scoreSvc.query({ oDataQuery: `$filter=enrollmentId eq ${this.props.enrollmentId}&$orderBy=group/index asc, group/name asc` }, true)
            .then(res => {
                this.setState({ scores: res })
                this.showLoading(false);
            })
            .catch(err => this.handleError(err))
    }

    getData = (): void => {
        this.showLoading(true);
        this.certEnrollmentdataSvc.query({ oDataQuery: `$filter=id eq ${this.props.enrollmentId} &$expand=User` })
            .then(res => {
                if (res && res.length > 0) {
                    this.setState({ data: res[0], user: res[0].user })
                }
                this.showLoading(false);
            })
            .catch(err => this.handleError(err))
    }

    changeDateValue = (date: any, property: string) => {
        const _data: any = Object.assign({}, this.state.data)
        _data[property] = date;
        this.setState({ data: _data })
    }

    changeValue = (e: any, property: string) => {
        const _data: any = Object.assign({}, this.state.data)
        _data[property] = e.target.value;
        this.setState({ data: _data })
    }

    saveData = () => {
        this.certEnrollmentdataSvc.update(this.state.data, null, false)
            .then(res => {
                this.setState({ data: res });
                this.notifySuccess('Enrollment updated successfully');
            })
            .catch(err => this.handleError(err))
    }

    getValueOfSection = (score: any): React.ReactFragment => {
        const noCompleted = score.score < score.requiredScore;
        return (
            <React.Fragment>
                {noCompleted ?
                    <React.Fragment>
                        {score.score} / <span className="required-score">{score.requiredScore} </span>
                    </React.Fragment>
                    : score.score
                }
                {/* {noCompleted ? null : <i className="material-icons">  check</i>} */}
            </React.Fragment>
        )
    }

    renderTotalHeaderSection = (score: any): string => {
        if (score.pointTypeId == 1) {
            return `${score.groupName}`
        }
        if (score.pointTypeId == 2) {
            return `${score.groupName} Points`
        }
        if (score.pointTypeId == 3) {
            return `${score.groupName} ${score.pointName} Points`
        }
    }

    renderRunningTotal = (): React.ReactFragment => {
        //return `${this.state.scores.map(s => s.score).reduce((a, b) => a + b, 0)} / ${this.state.scores.map(s => s.requiredScore).reduce((a, b) => a + b, 0)}`
        return (<React.Fragment>
            {this.state.scores.map(s => s.score).reduce((a, b) => a + b, 0)} / <span className="required-score">{this.state.scores.map(s => s.requiredScore).reduce((a, b) => a + b, 0)} </span>
        </React.Fragment>);
    }

    renderSections = (): React.ReactFragment => {
        return (
            <React.Fragment>
                {this.state.scores.map((score: any, i: number) =>
                    <div key={i} className="item-section-points">
                        {/* <div className="item-header">{this.renderTotalHeaderSection(score)}</div> */}
                        <div className="item-header">{score.scoreName} Points</div>
                        <div className={`item-value ${score.score < score.requiredScore ? 'disable' : 'complete-points'}`}>
                            {this.getValueOfSection(score)} {score.score < score.requiredScore ? null : <i className="material-icons">  check</i>}
                        </div>
                    </div>
                )}
                <div className="item-section-points">
                    <div className="item-header"><b>Total Points</b></div>
                    <div className="item-value">
                        {this.renderRunningTotal()}
                    </div>
                </div>
                {/* <div>
                    <div className="item-header">Total</div>
                    <div className='item-value'>
                        <Chip label={this.renderRunningTotal()} variant="outlined" />
                    </div>
                </div> */}
            </React.Fragment>
        )
    }

    approveEnrolment = () => new Promise((resolve, reject) => {
        this.approveDataSvc.update(this.state.data, null, false).then((res) => {
            this.notifySuccess('Enrollment has been successfully approved');
            this.setState({ data: res });
            this.certEnrollmentdataSvc.clearChache();
            resolve();
        }).catch(() => {
            this.notifyError('This enrollment could not be approved in this moment.');
            reject()
        })
    });



    render() {

        return (
            <div>
                <Container maxWidth="md">
                    <Card className="enrollment-general-info">
                        <CardContent >
                            <Typography color="textSecondary" gutterBottom variant="h5" component="h2" align="center">
                                {this.props.levelName} Point Totals
                            </Typography>

                            <Grid container
                                direction="row"
                                justify="space-around"
                                alignItems="flex-start">

                                <TextField
                                    fullWidth={true}
                                    margin="normal"
                                    label="Technician Name"
                                    value={`${this.state.user.firstName} ${this.state.user.lastName}`}
                                    disabled={true}
                                />
                            </Grid>

                            <Grid container
                                spacing={2}
                                direction="row"
                                justify="space-between"
                                alignItems="stretch"
                            >
                                <Grid item sm={6} xs={12}>
                                    {/* <KeyboardDatePicker
                                        fullWidth={true}
                                        margin="normal"
                                        autoOk
                                        variant="dialog"
                                        label="Date Enrolled"
                                        format="MM/DD/YYYY"
                                        value={this.state.data.enrolledDate}
                                        allowKeyboardControl={false}
                                        disabled={true}
                                        onChange={(date: any) => null}
                                    /> */}
                                    <DatePicker
                                        variant="inline"
                                        fullWidth={true}
                                        autoOk
                                        onError={() => { return true }}
                                        margin="normal"
                                        label="Date Enrolled"
                                        format="MM/DD/YYYY"
                                        disabled={true}
                                        value={this.state.data.enrolledDate}
                                        onChange={(date: any) => null}
                                    />

                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    {/* <KeyboardDatePicker
                                        fullWidth={true}
                                        margin="normal"
                                        autoOk
                                        variant="inline"
                                        label="Completion Date Goal"
                                        format="MM/DD/YYYY"
                                        value={this.state.data.completionGoalDate || null}
                                        allowKeyboardControl={false}
                                        disabled={this.user && this.user.role < UserRole.CompanyUser}
                                        onChange={(date: any) => this.changeDateValue(date, 'completionGoalDate')}
                                    /> */}
                                    <DatePicker
                                        variant="inline"
                                        fullWidth={true}
                                        autoOk
                                        onError={() => { return true }}
                                        margin="normal"
                                        id="date-to-dialog"
                                        label="Completion Date Goal"
                                        format="MM/DD/YYYY"
                                        disabled={this.user && this.user.role < UserRole.CompanyUser}
                                        value={this.state.data.completionGoalDate || null}
                                        onChange={(date: any) => this.changeDateValue(date, 'completionGoalDate')}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <FormControl fullWidth={true} style={{ marginTop: 17 }}>
                                        <InputLabel htmlFor="age-native-required">Supervisor</InputLabel>
                                        <Select
                                            value={+this.state.data.supervisorId || ''}
                                            onChange={(e) => this.changeValue(e, 'supervisorId')}
                                            name="Supervisor"
                                            disabled={this.user && this.user.role < UserRole.CompanyUser}
                                            input={<Input id="name" />}
                                        >
                                            {/* <MenuItem value={null}>select ...</MenuItem> */}
                                            {this.state.supervisions.map(user =>
                                                (<MenuItem key={user.id} value={user.id}> {user.firstName} {user.lastName}</MenuItem>)
                                            )}
                                        </Select>
                                        {/* {!+this.state.user.trade && this.state.selectError && <FormHelperText style={{ color: 'red' }}>this field is required</FormHelperText>} */}
                                    </FormControl>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    {/* <KeyboardDatePicker
                                        fullWidth={true}
                                        margin="normal"
                                        autoOk
                                        variant="inline"
                                        label="Completion Date"
                                        format="MM/DD/YYYY"
                                        value={this.state.data.completionDate || null}
                                        allowKeyboardControl={false}
                                        disabled={true}
                                        onChange={(date: any) => null}
                                    /> */}
                                    <DatePicker
                                        variant="inline"
                                        fullWidth={true}
                                        autoOk
                                        onError={() => { return true }}
                                        margin="normal"
                                        label="Completion Date"
                                        format="MM/DD/YYYY"
                                        disabled={true}
                                        value={this.state.data.completionDate || null}
                                        onChange={(date: any) => null}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth={true}
                                        margin="normal"
                                        label="Enrollment Status"
                                        value={(this.state.data.isApproved ? 'Approved' : (this.state.data.isReadyForApproval ? 'Ready for Approval' : 'In Process'))}
                                        disabled={true}
                                    />
                                </Grid >
                            </Grid>

                            {/* sections of summary_______________________________________________________  */}
                            <div className="section-points">
                                {this.renderSections()}
                            </div>
                            {/* _______________________________________________________sections of summary  */}

                        </CardContent>
                        <CardActions style={{ textAlign: 'right', display: 'block', padding: 15 }}>
                            {this.user && this.user.role >= UserRole.CompanyUser && this.user.role != UserRole.GlobalAdmin ? <Button variant="contained" disabled={!this.state.data.isReadyForApproval || this.state.data.isApproved} onClick={this.approveEnrolment}>Approve</Button> : ''}
                            {this.user && this.user.role >= UserRole.CompanyUser && this.user.role != UserRole.GlobalAdmin ? <Button color="primary" variant="contained" onClick={this.saveData} disabled={this.state.data.isApproved}>Save</Button> : ''}
                        </CardActions>
                    </Card>
                </Container>
            </div >
        )
    }


}