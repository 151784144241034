import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Fab } from '@material-ui/core';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LockOpen from '@material-ui/icons/LockOpen';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import { User } from 'CORE/entities/User';

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles(theme => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);


class IHeaderMenuProps {
    user: User;
    onCangePassword: () => void;
    onLogOut: () => void;

}
class IHeaderMenuState {
    anchorEl: HTMLElement
}


export default class HeaderMenu extends React.Component<IHeaderMenuProps, IHeaderMenuState> {

    constructor(props: IHeaderMenuProps) {
        super(props);

        this.state = {
            anchorEl: null,
        } as IHeaderMenuState
    }

    setInitials = (user: User): string => {
        const name = `${user.firstName} ${user.lastName}`;
        let initials: any = name.match(/\b\w/g) || [];
        return initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    }

    handleClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ anchorEl: event.currentTarget })
    }

    handleClose = () => {
        this.setState({ anchorEl: null })
    }


    customizedMenus = () => {
        return (
            <div>
                <div className="user-info-section" onClick={this.handleClick}>
                    <Fab color="primary" aria-label="edit">
                        {this.setInitials(this.props.user)}
                    </Fab>
                </div>
                <StyledMenu
                    id="customized-menu"
                    anchorEl={this.state.anchorEl || null}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleClose}
                >
                    <StyledMenuItem onClick={() => { this.props.onCangePassword(), this.handleClose() }}>
                        <ListItemIcon>
                            <LockOpen />
                        </ListItemIcon>
                        <ListItemText primary="Change password" />
                    </StyledMenuItem>

                    <StyledMenuItem onClick={() => { this.props.onLogOut(), this.handleClose() }}>
                        <ListItemIcon>
                            <PowerSettingsNew />
                        </ListItemIcon>
                        <ListItemText primary="Log out" />
                    </StyledMenuItem>
                </StyledMenu>
            </div>
        );
    }

    render() {

        return (
            <div>{this.customizedMenus()}</div>
        )
    }
}