import BaseDataService from 'CORE/data-service/BaseDataService';
import { IServiceConfig } from 'CORE/interfaces/IServiceConfig';
import { BaseEntity } from 'CORE/entities/BaseEntity';
import { ConfigHelper } from 'CORE/utils/ConfigHelper';
import { KeyHelper } from 'CORE/utils/KeyHelper';
import { MemoryCache } from 'CORE/utils/MemoryCache';
import { IQueryParams, ISelectParams } from 'CORE/interfaces/IUrlBuilder';


export interface IResourceServiceConfig extends IServiceConfig {
}

export interface IQueryResult<T extends BaseEntity> {
    data: T[];
    total: number;
}

export default class ResourceDataService<T extends BaseEntity> extends BaseDataService {

    protected cache: MemoryCache = MemoryCache.getInstance();
    protected dependencies: string[] = [];
    protected cacheMinutes: number = 10;
    protected generator: KeyHelper = new KeyHelper();

    constructor(config: IResourceServiceConfig | string) {
        super(typeof (config) === 'string' ? ConfigHelper.getDefaultConfig(config) : ConfigHelper.ensureConfig(config));
    }

    query(params: IQueryParams, noCache: boolean = false, ): Promise<T[]> {
        let key = !params.skip && !params.take ? this.generator.genKey([`${this.url}${params.oDataQuery ? params.oDataQuery : ''}`, params.filter ? params.filter : '', params.order ? params.order : '']) : '';
        if (key && !noCache) {
            const key = this.generator.genKey([`${this.url}${params.oDataQuery ? params.oDataQuery : ''}`, params.filter, params.order]);

            if (this.cache.keyExist(key)) {
                return Promise.resolve(this.cache.get(key))
            };
            if (this.cache.keyExist(`${key}_promise`)) {
                return this.cache.get(`${key}_promise`)
            };
        }

        // if (this.config.filterCaching && !noCache) {
        //     const filterKey = this.generator.genKey([`${this.url}`, 'filtering']);
        //     this.cache.cache(filterKey, params.filter);
        // }

        const promise = super.requestJSON(this.url + this.config.urlBuilder.getQueryParams(params))
            .then(res => {
                const _res = res.data ? res.data : res

                // const _res = res;
                if (key) {
                    this.cache.cache(key, _res, this.cacheMinutes, this.dependencies);
                }
                return _res;
            });

        if (key) {
            this.cache.cache(`${key}_promise`, promise, this.cacheMinutes, this.dependencies);
        }

        return promise;
    }

    select(params: ISelectParams): Promise<T> {
        return super.requestJSON(this.url + this.config.urlBuilder.getSelectParams(params))
            .then(res => {
                // return res.data ? res.data : res;
                return res;
            })
    }

    clearChache() {
        this.cache.clear(this.url, this.config.dependencies ? this.config.dependencies : []);
    }
}