import React from 'react';
import BaseComponent from 'CORE/base-classes/BaseComponent';
import DataService from 'CORE/data-service/DataService';
import { IState, IProps } from 'CORE/interfaces/main-interfaces';

import { Container, Typography, Card, CardContent, Table, TableHead, TableRow, TableCell, TableBody, Paper, CardActionArea, CardActions, Button, CardMedia, Grid, PropTypes } from "@material-ui/core";
import CertEnrolmentsComponent from './admin/cert-enrolments.component';
import { User } from 'CORE/entities/User';
import UserCertificateStatus from 'CORE/entities/UserCertificateStatus';
import { CardMembership } from '@material-ui/icons'

interface IEnrollmentProps extends IProps {
    history: any;
    location: any;
}

interface IEnrollmentState extends IState {
    availableCertificates: UserCertificateStatus[];
}


export default class EnrollmentList extends BaseComponent<IEnrollmentProps, IEnrollmentState> {
    private availableCertLookupDataSvc: DataService<UserCertificateStatus> = new DataService('certifications/certificates/userStatuses');



    constructor(props: IEnrollmentProps) {
        super(props);

    }

    getInitialState() {
        return {
            availableCertificates: []

        } as IEnrollmentState;
    }

    authCompleted() {
        if (this.user.id) {
            this.getData(this.user.id);
        }
    }

    getData = (id: number): void => {
        this.availableCertLookupDataSvc.query({ filter: `userId eq ${id} and isNextAvailable eq true`, order: 'direction,name asc', includes: "certificate" })
            .then(res => {
                this.setState({ availableCertificates: res })
            });
    }

    render() {
        return (
            <div>
                <Container maxWidth="lg" className="user-enrollment-wrapper">
                    <Grid container spacing={3} direction="row" alignItems="center">
                        <Grid item xs={12}>
                            <CertEnrolmentsComponent history={this.props.history} location={this.props.location}></CertEnrolmentsComponent>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} direction="row" alignItems="center" className="margin-top-100">
                        <Grid item xs={12}>
                            <Paper className="cert-info-wrapper">
                                <Typography gutterBottom variant="h5" component="h6">
                                    Available certificates<hr />
                                </Typography>
                                {this.state.availableCertificates.length > 0 ?
                                    '' :
                                    <Typography gutterBottom >There are no available certificates for your level.</Typography>
                                }
                                <Grid container spacing={3} direction="row">
                                    {this.state.availableCertificates.map((el: any, i: number) =>
                                        <Grid item lg={3} md={4} sm={6} xs={12} key={i}>
                                            <Card>
                                                <CardContent >
                                                    <Typography gutterBottom className='custom-TextSecondary'>
                                                        Certificate
                                                </Typography>
                                                    {/* <CardActionArea> */}
                                                    <CardContent>
                                                        <Typography gutterBottom variant="h5" component="h2">
                                                            {el.direction}
                                                        </Typography>
                                                        <Typography gutterBottom variant="h5" component="h2">
                                                            {el.name}
                                                        </Typography>
                                                        <Typography variant="body2" className='custom-TextSecondary' component="p">
                                                            {/* <CardMembership />  */}
                                                            Required score: {el.certificate.requiredScore}
                                                        </Typography>
                                                    </CardContent>
                                                    {/* </CardActionArea> */}
                                                    <CardActions>
                                                        <Button color="primary" onClick={() => this.props.history.push(`/enrollment/${el.certificateId}`)}>
                                                            Enroll
                                                    </Button>
                                                    </CardActions>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    )}
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </div >
        )
    }
}