import * as React from 'react';
import MaterialTable, { Column, QueryResult, MTableToolbar } from 'material-table';
import { EditableListBase, IListProps, IEditableListState } from 'CORE/base-classes/ListBase';
import { BaseEntity } from 'CORE/entities/BaseEntity';
import { IEditableListConfig } from 'CORE/interfaces/IListConfig';
import TextField from '@material-ui/core/TextField';
import { IconButton, Grid, Checkbox, FormControlLabel, Tooltip, FormControl, Input, Card, CardContent } from '@material-ui/core/';
import SearchIcon from '@material-ui/icons/Search';
import FilterList from '@material-ui/icons/FilterList';
import Clear from '@material-ui/icons/Clear';
import InputAdornment from '@material-ui/core/InputAdornment';
import { IValidationError, ValidationType, IValidationRule } from 'CORE/interfaces/IModelState';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';

interface ICompaniesProps extends IListProps {

}

interface ICompaniesState extends IEditableListState<Company> {
    columns: Column<Company>[];
    filterByName: string;
    filterByPhone: string;
    filterByEmail: string;
    filterByContactPerson: string;
    filterShowOnlyInactive: boolean;
}

export class Company extends BaseEntity {
    email?: string;
    phone?: string;
    contactPerson?: string;
    isActive: boolean;
    address: string;
    city: string;
    state: string;
    zip: string;

    validate(): IValidationError[] {
        let errors: IValidationError[] = [];
        errors = this.logErrors(errors, this.validateField('Name', this.name, [{ validationType: ValidationType.required } as IValidationRule, { validationType: ValidationType.maxLength, params: [250] } as IValidationRule]));
        errors = this.logErrors(errors, this.validateField('Email', this.email, [{ validationType: ValidationType.email } as IValidationRule, { validationType: ValidationType.maxLength, params: [250] } as IValidationRule]));
        errors = this.logErrors(errors, this.validateField('Address', this.address, [{ validationType: ValidationType.maxLength, params: [500] } as IValidationRule]));
        errors = this.logErrors(errors, this.validateField('City', this.city, [{ validationType: ValidationType.maxLength, params: [250] } as IValidationRule]));
        errors = this.logErrors(errors, this.validateField('State', this.state, [{ validationType: ValidationType.maxLength, params: [250] } as IValidationRule]));
        errors = this.logErrors(errors, this.validateField('Zip Code', this.zip, [{ validationType: ValidationType.isZipCode } as IValidationRule]));
        return errors;
    }
}

export default class CompaniesComponent extends EditableListBase<Company, ICompaniesProps, ICompaniesState>{
    tableRef: React.RefObject<any>;
    returnFirstPage: boolean = false;
    constructor(props: ICompaniesProps) {
        super(props, { url: 'companies', type: Company } as IEditableListConfig<Company>);
        this.tableRef = React.createRef();
        this.take = 10;
    }

    filterData = () => {
        this.skip = 0;
        this.tableRef.current;
        this.tableRef.current.onQueryChange({page: 0});
        const cancelBtn: any = document.querySelectorAll('[title="Cancel"]')[0];
        if (cancelBtn) { cancelBtn.click(); }
    }

    getInitialState() {
        return {
            data: [],
            columns: [
                { title: 'Name', field: 'name', customSort: () => 0 },
                { title: 'Phone', field: 'phone', customSort: () => 0 },
                { title: 'Email', field: 'email' },
                { title: 'Address', field: 'address', customSort: () => 0 },
                { title: 'City', field: 'city', customSort: () => 0  },
                { title: 'State', field: 'state', customSort: () => 0  },
                { title: 'Zip', field: 'zip', customSort: () => 0  },
                { title: 'ContactPerson', field: 'contactPerson', customSort: () => 0  },
                {
                    title: 'Active', field: 'isActive', type: "boolean", editable: 'onUpdate', initialEditValue: true, customSort: () => 0, 
                    render: rowData => rowData.isActive || rowData.isActive === undefined ? <CheckBox className='grean-icon' /> : <CheckBoxOutlineBlank className='grey-icon' />
                }
            ],
            filterByName: '',
            filterByPhone: '',
            filterByEmail: '',
            filterByContactPerson: '',
            filterShowOnlyInactive: false
        } as ICompaniesState;
    }

    buildFilter() {
        this.filterStr = 'id gt 2 ' + (this.state.filterShowOnlyInactive ? 'and isActive eq false' : 'and isActive eq true');
        this.filterStr = this.filterStr + (this.state.filterByName ? ` and contains(Name, '${this.state.filterByName}')` : '');
        this.filterStr = this.filterStr + (this.state.filterByPhone ? ` and contains(Phone, '${this.state.filterByPhone}')` : '');
        this.filterStr = this.filterStr + (this.state.filterByEmail ? ` and contains(Email, '${this.state.filterByEmail}')` : '');
        this.filterStr = this.filterStr + (this.state.filterByContactPerson ? ` and contains(ContactPerson, '${this.state.filterByContactPerson}')` : '');
    }

    clearFilter() {
        this.setState({ filterByName: '' });
        this.setState({ filterByPhone: '' });
        this.setState({ filterByEmail: '' });
        this.setState({ filterByContactPerson: '' });
        this.setState({ filterShowOnlyInactive: false });
    }

    render() {
        return (
            <div className="admin-table">
                <Card>
                    <CardContent>
                        <div className="admin-filter-wrapper">
                            <Grid container spacing={3} direction="row" alignItems="center">
                                <Grid item lg={2} sm={4} xs={6}>
                                    <TextField id="name-filter" label="Name" autoComplete='nope'
                                        value={this.state.filterByName}
                                        onChange={e => this.setState({ filterByName: e.target.value })} onKeyPress={(e) => { if (e.key === 'Enter') { (this.filterData()) } }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <FilterList />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={2} sm={4} xs={6}>
                                    <TextField id="phone-filter" label="Phone" autoComplete='nope'
                                        value={this.state.filterByPhone}
                                        onChange={e => this.setState({ filterByPhone: e.target.value })} onKeyPress={(e) => { if (e.key === 'Enter') { (this.filterData()) } }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <FilterList />
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>
                                <Grid item lg={2} sm={4} xs={6}>
                                    <TextField id="email-filter" label="Email" autoComplete='nope'
                                        value={this.state.filterByEmail}
                                        onChange={e => this.setState({ filterByEmail: e.target.value })} onKeyPress={(e) => { if (e.key === 'Enter') { (this.filterData()) } }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <FilterList />
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>
                                <Grid item lg={2} sm={4} xs={6}>
                                    <TextField id="contact-person-filter" label="Contact Person" autoComplete='nope'
                                        value={this.state.filterByContactPerson}
                                        onChange={e => this.setState({ filterByContactPerson: e.target.value })} onKeyPress={(e) => { if (e.key === 'Enter') { (this.filterData()) } }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <FilterList />
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>
                                <Grid item lg={2} sm={4} xs={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.filterShowOnlyInactive}
                                                onChange={(e) => { this.setState({ filterShowOnlyInactive: e.target.checked }); this.filterData(); }}
                                                value="checkedB"
                                                color="primary"
                                            />
                                        }
                                        label="Show Only Inactive"
                                        classes={{ label: 'filter-checkbox-label' }}
                                    />
                                </Grid>
                                <Grid item lg={2} sm={4} xs={6} className="admin-filter-buttons">
                                    <Tooltip title="Run Filter" aria-label="runFilter">
                                        <IconButton aria-label="runFilter" onClick={() => {this.filterData()}}>
                                            <SearchIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Clear Filter" aria-label="clearFilter">
                                        <IconButton aria-label="clearFilter" onClick={() => { this.clearFilter(); (this.filterData()) }}>
                                            <Clear />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </div>
                    </CardContent>
                </Card>
                <div className="admin-table-material">
                    <MaterialTable
                        tableRef={this.tableRef}
                        columns={this.state.columns}
                        data={query =>
                            new Promise((resolve, reject) => {
                                this.skip = this.returnFirstPage ? 0 : query.page * query.pageSize;
                                this.returnFirstPage = false;
                                this.take = query.pageSize;
                                this.order = query.orderBy ? `${query.orderBy.field} ${query.orderDirection}` : this.order;
                                this.buildFilter();
                                this.getData().then((res) => {
                                    resolve(res as QueryResult<Company>);
                                }).catch(() => reject())
                            })
                        }
                        title="Companies"
                        options={{
                            initialPage: (this.skip / this.take),
                            pageSize: this.take,
                            pageSizeOptions: [10, 25, 50],
                            showEmptyDataSourceMessage: true,
                            debounceInterval: 1000,
                            actionsColumnIndex: -1,
                            addRowPosition: "first",
                            search: false
                        }}
                        editable={{
                            onRowAdd: (newData) =>
                                new Promise((resolve, reject) => {
                                    newData = this.trimAllStrings(newData);
                                    if (!this.validateModel(newData)) { return reject(); }
                                    setTimeout(() => {
                                        //ToDo: investigate why setState doesn't work without settimeout
                                        this.setState({ newItem: newData });
                                        this.create().then(() => {
                                            this.cleanCache();
                                            resolve();
                                        }).catch(() => reject());
                                    }, 100);
                                }),
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve, reject) => {
                                    newData = this.trimAllStrings(newData);
                                    newData.phone = newData.phone || null;
                                    //https://github.com/mbrn/material-table/issues/537
                                    this.returnFirstPage = true;
                                    if (!this.validateModel(newData)) { return reject(); }
                                    this.update(newData, false).then(() => {
                                        this.cleanCache();
                                        resolve();
                                    }).catch(() => reject());
                                })
                        }}
                    />
                </div>
            </div>
        )
    }
}