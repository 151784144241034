import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import CompaniesComponent from './companies.component';
import UsersComponent from './users.component';
import PeopleIcon from '@material-ui/icons/People';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import { UserRole, User } from 'CORE/entities/User';
import { PrivateRoute } from '../auth/private-route';
import CertEnrolmentsComponent from './cert-enrolments.component';
import MainComponent from '../main-component';
import AppBarMenu, { AdminTab } from '../common/AppBarMenu';

interface IAdminState {
}

interface IAdminProps {
  history: any;
  location: Location;
}

const tabs: AdminTab[] = [
  { title: "Enrollments", tooltip: 'Create & Manage', routerLink: "/admin/enrollments", role: UserRole.CompanyUser, icon: <LibraryBooksIcon /> },
  { title: "Users",  tooltip: 'Create & Manage', routerLink: "/admin/users", role: UserRole.CompanyUser, icon: <PeopleIcon /> },
  { title: "Companies",  tooltip: 'Create & Edit', routerLink: "/admin/companies", role: UserRole.GlobalAdmin, icon: <LocationCityIcon /> },
]

export default class AdminComponent extends React.Component<IAdminProps, IAdminState>{

  constructor(props: IAdminProps) {
    super(props);
  }

  render() {
    return (
      <AppBarMenu menuItems={tabs} location={this.props.location} history={this.props.history}>
        <Route exact path='/admin' render={() => (<Redirect to="/admin/enrollments" />)} />
        <PrivateRoute path="/admin/companies" roles={UserRole.GlobalAdmin} component={CompaniesComponent} />
        <Route path='/admin/users' render={(props) => <UsersComponent showTrackerUserRole={true} {...props} />} />
        <Route exact path='/admin/enrollments' render={(props) => <CertEnrolmentsComponent {...props} />} />
        <PrivateRoute path='/admin/enrollments/:certId/:enrollId' component={MainComponent} />
      </AppBarMenu>
    )
  }
}