import * as React from 'react';
import { Typography, Grid, Checkbox, Slider, IconButton, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import BaseComponent from 'CORE/base-classes/BaseComponent';
import { IProps, IState } from 'CORE/interfaces/main-interfaces';
import { CertPoint } from './module-group';
import { Description } from './main-component';
import { ItemDescription } from './certification-items/item-description';
import { ProgressControl } from './certification-items/progress-control';


interface IModuleItemProps extends IProps {
    onChange: (minus: boolean, type: PointType, multiplier?: number, i?: number) => void;
    onRemove: (delItems: CertPoint[]) => void;
    description: Description
    pointsConfig: any[];
    allPoints: any;
    readonly: boolean;
}

export class EditItemConfig {
    minus: boolean;
    type: number;
}

export enum PointType {
    Education = 1,
    Technical1 = 2,
    Technical2 = 4,
    //required 4 tech points to be set
    MentorShip1 = 3,
    //required 3 tech points to be set
    MentorShip2 = 5,
    MentorShip3 = 6,
    MentorShip4 = 7
}

export class IModuleItemState extends IState {
}


export default class ModuleItem extends BaseComponent<IModuleItemProps, IModuleItemState> {

    constructor(props: IModuleItemProps) {
        super(props)
    }

    getInitialState() {
        return {
        } as IModuleItemState;
    }

    componentDidMount() {
    }

    isEditable = (prevValue: number, category: number): boolean => {
        if (category == 0) { return true; }
        if (category == 1) { return prevValue < 4; }
        if (category == 2) { return prevValue < 3; }
    }

    change = (minus: boolean, config: any, i: number) => {
        if (minus) {
            if ((config.pointTypeId == PointType.Technical1 && this.props.allPoints[config.pointTypeId].length == 4 && this.props.allPoints[PointType.MentorShip1].length > 0)) {
                this.props.onRemove(this.props.allPoints[config.pointTypeId + 1]);
                return;
            }
        }
        this.props.onChange(minus, config.pointTypeId);
    }

    render() {

        return (
            <div className="module-item">
                <Grid
                    spacing={2}
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center">

                    <Grid item lg={6} xs={12}>
                        <ItemDescription
                            title={this.props.description.text}
                            description={this.props.description.description}
                            referenceUrl={this.props.description.referenceUrl}>
                        </ItemDescription>
                    </Grid>

                    {
                        this.props.pointsConfig ?
                            this.props.pointsConfig.map((config: any, i: number) =>
                                <React.Fragment key={i}>
                                    {
                                        config.pointTypeId == PointType.Education ?
                                            <Grid key={i} item xs={12} lg={'auto'}>
                                                <div style={{ textAlign: 'center' }}>
                                                    <div className="points-value">{config.pointType.value} points</div>
                                                    <Checkbox
                                                        disabled={this.props.readonly}
                                                        checked={this.props.allPoints[config.pointTypeId].length > 0}
                                                        onChange={() => this.change(null, config, null)}
                                                        value="checkedB"
                                                        color="primary"
                                                    />
                                                </div>
                                            </Grid >
                                            :
                                            config.pointTypeId == PointType.Technical1 ?
                                                <Grid item lg={3} sm={6} xs={12}>
                                                    <div className="rete-slider">
                                                        <Typography id="discrete-slider" gutterBottom component={'div'}>
                                                            {config.pointType.name}
                                                            <div className="points-value">{config.pointType.value} points</div>
                                                        </Typography>
                                                        <Slider
                                                            value={this.props.allPoints[config.pointTypeId].length}
                                                            defaultValue={0}
                                                            getAriaValueText={null}
                                                            aria-labelledby="discrete-slider"
                                                            valueLabelDisplay="off"
                                                            marks
                                                            step={1}
                                                            min={0}
                                                            max={config.maxCount}
                                                        />
                                                        {
                                                            !this.props.readonly ?
                                                                <div className="btns-rete-slider">
                                                                    <IconButton onClick={() => this.change(true, config, null)} disabled={this.props.allPoints[config.pointTypeId].length < 1}>
                                                                        <i className="material-icons">remove</i>
                                                                    </IconButton>
                                                                    <span className="step-value">  {this.props.allPoints[config.pointTypeId].length || 0} / {config.maxCount} </span>
                                                                    <IconButton onClick={() => this.change(false, config, null)} disabled={this.props.allPoints[config.pointTypeId].length >= config.maxCount}>
                                                                        <i className="material-icons">add</i>
                                                                    </IconButton>
                                                                </div> : null
                                                        }
                                                    </div>

                                                </Grid>
                                                :
                                                config.pointTypeId == PointType.MentorShip1 ?
                                                    <Grid item lg={3} sm={6} xs={12}>
                                                        <div className={`rete-slider ${this.isEditable(this.props.allPoints[config.pointTypeId - 1].length, config.pointType.category) ? 'disable' : ''}`}>
                                                            <Typography id="discrete-slider" gutterBottom component={'div'}>
                                                                {config.pointType.name}
                                                                <div className="points-value">{config.pointType.value} points</div>
                                                            </Typography>
                                                            <Slider
                                                                value={this.props.allPoints[config.pointTypeId].length}
                                                                defaultValue={0}
                                                                getAriaValueText={null}
                                                                aria-labelledby="discrete-slider"
                                                                valueLabelDisplay="off"
                                                                marks
                                                                step={1}
                                                                min={0}
                                                                max={config.maxCount}
                                                            />
                                                            {
                                                                !this.props.readonly ?
                                                                    <div className="btns-rete-slider">
                                                                        <IconButton onClick={() => this.change(true, config, null)} disabled={this.props.allPoints[config.pointTypeId].length < 1}>
                                                                            <i className="material-icons">remove</i>
                                                                        </IconButton>
                                                                        <span className="step-value">
                                                                            {this.props.allPoints[config.pointTypeId].length || 0} / {config.maxCount}
                                                                        </span>
                                                                        <IconButton onClick={() => this.change(false, config, null)} disabled={this.props.allPoints[config.pointTypeId].length >= config.maxCount}>
                                                                            <i className="material-icons">add</i>
                                                                        </IconButton>
                                                                    </div> : null
                                                            }
                                                        </div>


                                                    </Grid>
                                                    :

                                                    null
                                    }
                                </React.Fragment>
                            ) : null
                    }

                </Grid >
            </div >
        )
    }
}