import { BaseEntity } from "./BaseEntity";

export default class Certificate extends BaseEntity {
    direction: string;
    type: number;
    requiredScore: number;
    isActive: boolean;
    preRequiredId: number;
    preRequired: Certificate;
}

export class CertificateRow extends Certificate {
    initialValueId: number;
    initialValueIsActive: boolean;
    index: number;
}