import * as React from 'react';
import videoTruTech from '../../../assets/presentation.mp4';
import BaseComponent from 'CORE/base-classes/BaseComponent';
import { IProps, IState } from 'CORE/interfaces/main-interfaces';


interface IPresentationState extends IState {
    showVideo: boolean;
    blocked: boolean;
}

export class IPresentationProps extends IProps {
    play: (f: () => void) => void;
    ended: () => void;
}


export default class VideoPresentation extends BaseComponent<IPresentationProps, IPresentationState> {

    constructor(props: IPresentationProps) {
        super(props)
    }

    private video: any;
    private keyCodes: string;

    getInitialState() {
        return {
            showVideo: false,
            blocked: false,
        } as IPresentationState;
    }

    componentDidMount() {
        document.addEventListener("keydown", this.keyPress, false);
        this.props.play(this.playVideo);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.keyPress, false);
        this.video.removeEventListener('ended', () => { }, false);
    }

    isSafari = (): boolean => navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') == -1 &&
        navigator.userAgent.indexOf('FxiOS') == -1;

    isIOS = (): boolean => {
        const iDevices = [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ];
        if (!!navigator.platform) {
            while (iDevices.length) {
                if (navigator.platform === iDevices.pop()) { return true; }
            }
        }
        return false;
    }

    playVideo = (): void => {
        //|| this.isSafari() || this.isIOS()
        if (this.state.blocked) {
            this.props.ended();
        }
        if (this.video && !this.state.blocked) {
            this.setState({ showVideo: true })
            this.video.play();
            this.video.addEventListener("ended", () => {
                this.setState({ showVideo: false })
                this.props.ended();
            });
        }
    }

    keyPress = (e: any): void => {
        this.keyCodes = this.keyCodes ? this.keyCodes + e.key : e.key;
        if (e.key == 'Control') {
            this.keyCodes = 'Control';
        }
        if (this.keyCodes == 'Control]') {
            this.notifyInfo(`Video was blocked`)
            this.setState({ blocked: true })
            this.keyCodes = null;
        }
        if (this.keyCodes == 'Control[' && this.state.blocked) {
            this.notifyWarning(`Video was unblocked`)
            this.setState({ blocked: false });
            this.keyCodes = null;
        }
    }

    render() {
        return (
            <div>
                <div className={`video-element ${this.state.showVideo ? 'show-video' : ''}`}>
                    <video autoPlay={false} loop={false} muted={this.isSafari() || this.isIOS()} playsInline={true}
                        ref={refVideo => this.video = refVideo}
                        src={videoTruTech}
                        width="100%"
                        height="100%"
                    />
                </div>
            </div>
        )
    }

}