import * as React from 'react';
import { Grid, TextField, Button, Card, CardContent, Typography, CardActions, Container, FormControl, InputLabel, Select, Input, MenuItem } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import BaseComponent from 'CORE/base-classes/BaseComponent';
import { IProps, IState } from 'CORE/interfaces/main-interfaces';
import DataService from 'CORE/data-service/DataService';

import { User } from 'CORE/entities/User';
import { CertCreateEnrollModel } from 'CORE/entities/CertificationEnrollment';
import UserCertificateStatus from 'CORE/entities/UserCertificateStatus';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';


interface IEnrollmentFormProps extends IProps {
    match: any;
    history: any;
}

interface IEnrollmentFormState extends IState {
    user: User;
    supervisors: User[]
    availableCertificate: UserCertificateStatus;
    supervisorId: number;
    completionGoalDate: Date;
}

export default class EnrollmentForm extends BaseComponent<IEnrollmentFormProps, IEnrollmentFormState> {
    private availableCertLookupDataSvc: DataService<UserCertificateStatus> = new DataService('certifications/certificates/userStatuses');
    private enrollDataSvc: DataService<CertCreateEnrollModel> = new DataService('certificationEnrollments');
    private userLookupSvc: DataService<User> = new DataService('users');

    private certificateId = this.props.match.params.certId;

    constructor(props: IEnrollmentFormProps) {
        super(props)
    }

    getInitialState() {
        return {
            user: {},
            supervisors: [],
            availableCertificate: new UserCertificateStatus(),
            supervisorId: 0,
            completionGoalDate: null
        } as IEnrollmentFormState;
    }

    authCompleted() {
        if (this.user.id) {
            this.getData();
            this.setState({ user: this.user});
        }
    }

    getData(useCache: boolean = true) {
        this.showLoading(true);
        if (!useCache)
            this.userLookupSvc.clearChache();
        this.availableCertLookupDataSvc.clearChache();
        forkJoin(
            this.userLookupSvc.query({ filter: "role eq '50' or role eq '100'", order: 'firstName asc' }),
            this.availableCertLookupDataSvc.query({ filter: `userId eq ${this.user.id} and isNextAvailable eq true and certificateId eq ${this.props.match.params.certId}`, includes: "certificate" }))
            .subscribe(([users, availableCertificate]) => {
                this.setState({ supervisors: users });
                if (availableCertificate.length > 0)
                    this.setState({ availableCertificate: availableCertificate[0] });
            }, () => { }, () => {
                this.showLoading(false)
            });
    }

    enrollToCertificate() {
        this.showLoading(true);
        let certCreateModel = new CertCreateEnrollModel();
        certCreateModel.userId = this.user.id;
        certCreateModel.certificateId = this.certificateId;
        certCreateModel.completionGoalDate = this.state.completionGoalDate;
        certCreateModel.supervisorId = this.state.supervisorId;
        if (certCreateModel.validate().length > 0) {
            this.notifyWarning(certCreateModel.validate()[0].errorMessage);
            this.showLoading(false);
            return;
        }
        this.enrollDataSvc.create(certCreateModel).then((res) => {
            this.notifySuccess(`You have sucsesfully enrolled to ${this.state.availableCertificate.name} ${this.state.availableCertificate.direction} certificate.`);
            this.props.history.push(`/enrollment/${this.state.availableCertificate.certificateId}/${res.id}`)
        }).finally(() => {
            this.showLoading(false);
        })
    }

    render() {

        return (
            <div>
                <Container maxWidth="md" className="enrollment-form">
                    <Card>
                        <CardContent >
                            <Typography color="textSecondary" gutterBottom variant="h5" component="h2" align="center">
                                {this.state.availableCertificate.name} - {this.state.availableCertificate.direction}
                            </Typography>

                            <Grid container
                                direction="row"
                                justify="space-around"
                                alignItems="flex-start">

                                <TextField
                                    fullWidth={true}
                                    margin="normal"
                                    label="Technician Name"
                                    value={`${this.state.user.firstName} ${this.state.user.lastName}`}
                                    disabled={true}
                                />
                            </Grid>

                            <Grid container
                                spacing={2}
                                direction="row"
                                justify="space-between"
                                alignItems="stretch"
                            >
                                <Grid item sm={6} xs={12}>
                                    <KeyboardDatePicker
                                        fullWidth={true}
                                        margin="normal"
                                        autoOk
                                        variant="inline"
                                        label="Completion Date Goal"
                                        format="MM/DD/YYYY"
                                        value={this.state.completionGoalDate || null}
                                        allowKeyboardControl={false}
                                        onChange={(date: any) => this.setState({completionGoalDate: date})}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <FormControl fullWidth={true} style={{ marginTop: 17 }}>
                                        <InputLabel htmlFor="age-native-required">Supervisor</InputLabel>
                                        <Select
                                            value={this.state.supervisorId || 0}
                                            onChange={(e) => this.setState({supervisorId: e.target.value as number})}
                                            name="Supervisor"
                                            input={<Input id="name" />}
                                        >
                                            {this.state.supervisors.map(user =>
                                                (<MenuItem key={user.id} value={user.id}> {user.firstName} {user.lastName}</MenuItem>)
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions style={{ textAlign: 'right', display: 'block', padding: 15 }}>
                            <Button color="primary" variant="contained" onClick={() => this.enrollToCertificate()}>Enroll</Button>
                        </CardActions>
                    </Card>
                </Container>
            </div >
        )
    }
}