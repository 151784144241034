import * as React from 'react';
import { Grid, CardActions, Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, MenuItem, Typography } from '@material-ui/core';
import ModuleItem, { PointType } from './module-item';
import DataService from 'CORE/data-service/DataService';
import BaseComponent from 'CORE/base-classes/BaseComponent';
import { IProps, IState } from 'CORE/interfaces/main-interfaces';
import { Group } from './main-component';
import { forkJoin } from 'rxjs';
import { UserRole } from 'CORE/entities/User';
import { CertEnrolments } from './admin/cert-enrolments.component';
import { TotalInfo } from './certification-items/total-info';
import { ItemDescription } from './certification-items/item-description';
import { PointItemWithMultiplier } from './certification-items/point-item-with-multiplier';
import { ProgressControl } from './certification-items/progress-control';
import { MemoryCache } from 'CORE/utils/MemoryCache';

class ConfirnObj {
    item: any;
    items: any[];
    header: string;
    message: string;
    onCancel: () => void;
    onOk: () => void;
}

interface ISubTabProps extends IProps {
    typePoints: number[];
    data: Group[];
    groupId: number;
    certificationId: number;
    enrollmentId: number;
    isDataSaved: (isDataSaved: () => Promise<string>) => Promise<string>;
}

class EditableItem {
    id: number;
    type: number;
    multiplier?: number;
}

interface ISubTabState extends IState {
    data: Group[];
    confirmObj: any;
    points: CertPoint[];
    editPoints: CertPoint[];

    editablePoints: EditableItem[];

    newMultiPoints: CertPoint[];
    dellMultiPoints: { id: number }[];

    groupState: CertPoint[];
    groupItems: GroupItem[];
    copyGroupState: CertPoint[];
    pointTypes: PointTypeDescription[];
    enrollmentInfo: CertEnrolments;

    canLeavePage: boolean;

    readonly: boolean;
}

export class CertPoint {
    id?: number = 0;
    multiplier?: number = 1;
    enrollmentId: number;
    itemId: number;
    pointTypeId: number;
    acceptMultiplier?: boolean = false;
    isActive?: boolean = false;
    isMultiplierChanged?: boolean = false;
    value?: number;
    index?: number;
}

export class GroupItem {
    enrollmentId: number;
    itemId: number;
    points: CertPoint[];
}

export class PointTypeDescription {
    id: number;
    name: string;
    value: number;
    category: number;
}

export default class ModuleGroup extends BaseComponent<ISubTabProps, ISubTabState> {
    private dataSvc: DataService<Group> = new DataService('certifications/certificates');
    private pointDataSvc: DataService<any> = new DataService('certificationEnrollments');
    private certPointDataSvc: DataService<any> = new DataService('certificationEnrollments/points');
    private scoreSvc: DataService<CertEnrolments> = new DataService('certificationEnrollments/requiredScore');
    _isMounted = false;
    homeButton: HTMLElement;
    groupModel: CertPoint[] = [];

    confirmDeletePoints = {
        item: new Group(),
        items: [new CertPoint()],
        header: "Information",
        message: "Values of MentorShip will be removed. Are you sure you want to continue?",
        onCancel: () => { this.setState({ confirmObj: null }) },
        onOk: () => { this.deletePoints() }
    }

    confirmOnLeavePage = {
        header: "Information",
        message: "Your changes have not been saved. Do you want to save this changes?",
        onCancel: () => { this.setState({ confirmObj: null, editPoints: this.state.points.slice(), groupState: this.state.copyGroupState.slice() }, () => this.clearCache()) },
        onOk: () => { this.leavingPage() }
    }

    constructor(props: ISubTabProps) {
        super(props)
    }

    clearCache = () => {
        this.scoreSvc.clearChache();
        this.pointDataSvc.clearChache();
        this.pointDataSvc.clearChache();
    }

    getInitialState() {
        return {
            data: [],
            editPoints: [],
            points: [],
            editablePoints: [],
            newMultiPoints: [],
            dellMultiPoints: [],
            groupState: [],
            groupItems: [],
            copyGroupState: [],
            enrollmentInfo: new CertEnrolments(),
            readonly: true,
            canLeavePage: false,
        } as ISubTabState;
    }

    componentDidMount() {
        this._isMounted = true;
        this.props.isDataSaved(this.isDataSaved);
        this.loadData();
        //ToDo: review and change this approach
        if (!this.props.groupId || !this.props.certificationId) {
            if (this._isMounted) {
                this.setState({ data: this.props.data })
            }
        }

        this.homeButton = document.getElementById('home-icon');
        if (this.homeButton) {
            this.homeButton.addEventListener("click", this.clickHomeButton, false)
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        if (this.homeButton) {
            this.homeButton.removeEventListener("click", this.clickHomeButton, false)
        }
    }

    clickHomeButton = (e: any) => {
        if (!this.state.canLeavePage) {
            e.preventDefault();
            this.setState({ confirmObj: this.confirmOnLeavePage });
            this.isDataSaved()
                .then(res => {
                    if (res == `ok`) {
                        this.setState({ canLeavePage: true }, () => {
                            this.homeButton.click();
                        })
                    }
                })
        }
    }

    loadData = () => {
        let tointTypeUrl = '';
        if (this.props.typePoints && this.props.typePoints.length == 1) {
            tointTypeUrl = `pointTypeId eq ${this.props.typePoints[0]} and Item/Group/ParentId eq ${this.props.groupId}`;
        } else {
            tointTypeUrl = `Item/GroupId eq ${this.props.groupId}`;
        }
        this.showLoading(true);
        forkJoin(
            this.pointDataSvc.query({ oDataQuery: `$filter=id eq ${this.props.enrollmentId} & $expand=ItemPoints($filter = ${tointTypeUrl} ) ` }),
            this.dataSvc.query({ oDataQuery: `$filter=id eq ${this.props.certificationId}&$expand=Groups($filter=Id eq ${this.props.groupId};$expand=Items($expand=Description,PointConfigs($expand=pointType) ))&$select=Groups` }))
            .subscribe(([points, schema]) => {
                let pointTypes: PointTypeDescription[] = [];
                let tempItems: GroupItem[] = [];

                if (points && points.length > 0) {
                    this.setState({ points: points[0].itemPoints.slice(), editPoints: points[0].itemPoints })
                    this.setState({ enrollmentInfo: points[0] as CertEnrolments });
                    this.setState({ readonly: (!this.user || this.user.role != UserRole.TrackerUser || points[0].isApproved) })
                }

                if (schema && schema.length > 0 && schema[0].groups && schema[0].groups.length > 0) {
                    this.setState({ data: schema[0].groups[0].items.sort((a: any, b: any) => a.index - b.index) })

                    let temp: any = [...schema[0].groups[0].items.sort((a: any, b: any) => a.index - b.index)];

                    for (let x = 0; x < temp.length; x++) {
                        //get items info
                        let newItem = new GroupItem();
                        newItem.enrollmentId = this.props.enrollmentId * 1;
                        newItem.itemId = temp[x].id;
                        tempItems.push(newItem);

                        for (let i = 0; i < temp[x].pointConfigs.length; i++) {
                            //colect unique point type configs
                            if (pointTypes.length == 0 || !pointTypes.some(m => m.id == temp[x].pointConfigs[i].pointType.id))
                                pointTypes.push(temp[x].pointConfigs[i].pointType);

                            //build points objects
                            for (let j = 0; j < temp[x].pointConfigs[i].maxCount; j++) {
                                let certPoint = new CertPoint;
                                certPoint.pointTypeId = temp[x].pointConfigs[i].pointTypeId;
                                certPoint.itemId = temp[x].pointConfigs[i].itemId;
                                certPoint.enrollmentId = this.props.enrollmentId;
                                certPoint.acceptMultiplier = temp[x].pointConfigs[i].acceptMultiplier;
                                certPoint.index = j;
                                certPoint.value = temp[x].pointConfigs[i].pointType.value;
                                this.groupModel.push(certPoint);
                            }
                        }
                    }

                    if (this._isMounted) {

                        this.setState({ groupItems: tempItems })
                        this.setState({ pointTypes: pointTypes });
                    }
                    if (points && points.length > 0 && points[0].itemPoints) {
                        //initialize schema object
                        this.initializeCurrentGroupState(points[0].itemPoints);
                    }
                }
                this.showLoading(false);
            }, (err: any) => { this.handleError(err) }, () => {
                this.showLoading(false)
            })
    }

    //initialize schema object
    initializeCurrentGroupState = (itemPoints: any) => {
        //deep copy of group point items model           
        let tempGroupModel = JSON.parse(JSON.stringify(this.groupModel)) as CertPoint[];
        if (itemPoints.length > 0) {
            for (let i = 0; i < itemPoints.length; i++) {
                let item = tempGroupModel.filter(x => x.itemId == itemPoints[i].itemId && x.pointTypeId == itemPoints[i].pointTypeId && x.isActive == false)
                item[0].isActive = true;
                item[0].multiplier = itemPoints[i].multiplier;
                item[0].id = itemPoints[i].id;
            }
        }
        if (this._isMounted) {
            const _copyGroupState = JSON.parse(JSON.stringify(tempGroupModel)) as CertPoint[];
            this.setState({ groupState: tempGroupModel, copyGroupState: _copyGroupState });
        }
    }

    isDataSaved = (): Promise<string> => {
        return new Promise((resolve, reject) => {
            const arrActions = this.arrayForAction();

            if (arrActions && (arrActions.create.length > 0 || arrActions.delete.length > 0)) {
                this.setState({ confirmObj: this.confirmOnLeavePage })
            } else {
                resolve(`ok`);
            }
        })
    }

    leavingPage = () => {
        if (this.state.data[0].pointConfigs[0].pointTypeId < PointType.Technical2) {
            this.saveData();
        } else {
            this.saveDataNewPUT();
        }
        this.setState({ confirmObj: null })
    }

    arrayForAction = (): { create: CertPoint[], delete: { id: number }[] } => {
        const newPoints: CertPoint[] = [];
        const delPoints: { id: number }[] = [];

        if (this.state.data[0].pointConfigs[0].pointTypeId == PointType.Education) {
            this.state.editPoints.map((el: CertPoint, i: number) => {
                const _index = this.state.points.findIndex((x: CertPoint) => x.itemId == el.itemId);
                if (_index >= 0) {
                } else {
                    newPoints.push(el)
                }
            })
            this.state.points.map((pointEl: CertPoint) => {
                const _index = this.state.editPoints.findIndex((x: CertPoint) => x.itemId == pointEl.itemId);
                if (_index >= 0) {
                } else {
                    if (pointEl.id) {
                        delPoints.push({ id: pointEl.id })
                    }
                }
            })
        } else {
            if (this.state.data[0].pointConfigs[0].pointTypeId == PointType.Technical1) {
                this.state.editablePoints.map((element: EditableItem) => {
                    let beroreTechPoints: CertPoint[] = this.state.points.filter((x: CertPoint) => x.itemId == element.id && x.pointTypeId == element.type);
                    const afterTechPoints: CertPoint[] = this.state.editPoints.filter((x: CertPoint) => x.itemId == element.id && x.pointTypeId == element.type);

                    if (beroreTechPoints.length > afterTechPoints.length) {
                        for (let i = beroreTechPoints.length - 1 - afterTechPoints.length; i >= 0; i--) {
                            if (beroreTechPoints[i].id) {
                                delPoints.push({ id: beroreTechPoints[i].id })
                            }
                        }
                    }
                    if (afterTechPoints.length > beroreTechPoints.length) {
                        for (let i = afterTechPoints.length - 1 - beroreTechPoints.length; i >= 0; i--) {

                            newPoints.push(
                                {
                                    multiplier: element.multiplier,
                                    itemId: element.id,
                                    enrollmentId: +this.props.enrollmentId,
                                    pointTypeId: element.type
                                }
                            )
                        }
                    }
                })
            } else {
                if (this.state.data[0].pointConfigs[0].pointTypeId > PointType.MentorShip1) {
                    this.state.groupState.map((item: CertPoint, index: number) => {
                        if (JSON.stringify(item) != JSON.stringify(this.state.copyGroupState[index])) {
                            newPoints.push(item);
                        }
                    })

                }
            }
        }
        return { create: newPoints, delete: delPoints };
    }

    saveData = () => {
        const arrActions = this.arrayForAction();

        const newPoints = arrActions.create;
        const delPoints = arrActions.delete;

        // ____________________ deleting elements__________________
        if (delPoints.length > 0) {
            this.showLoading(true);
            this.certPointDataSvc.deleteArr(delPoints)
                .then(() => {
                    this.setState({ points: this.state.editPoints.slice(), editablePoints: [] })
                    this.notifySuccess('Certification points have been successfully updated.');
                    this.showLoading(false);

                    // ____________________ creating elements__________________
                    if (newPoints.length > 0) {
                        this.showLoading(true);
                        this.certPointDataSvc.create(newPoints)
                            .then((res: CertPoint[]) => {
                                res.map((item: CertPoint) => {
                                    this.setState((prevState: ISubTabState) => {
                                        const inx = prevState.editPoints.findIndex((x: CertPoint) => x.itemId == item.itemId && x.pointTypeId == item.pointTypeId);
                                        if (inx >= 0) {
                                            prevState.editPoints[inx] = item;
                                        }
                                        return { editPoints: prevState.editPoints, editablePoints: [] }
                                    }, () => { this.setState({ points: this.state.editPoints.slice() }) })
                                })
                                this.notifySuccess('Certification points have been successfully updated.');
                                this.showLoading(false);
                            })
                            .catch((err: any) => {
                         
                                this.handleError(err);
                            })
                            .finally(() => {
                                this.pointDataSvc.clearChache();
                                this.scoreSvc.clearChache();
                                this.pointDataSvc.clearChache();
                                this.loadData();
                            })
                    }
                })
                .catch((err: any) => this.handleError(err))
                .finally(() => {
                    this.pointDataSvc.clearChache();
                    this.scoreSvc.clearChache();
                    this.pointDataSvc.clearChache();
                })
        } else {
            // ____________________ creating elements__________________
            if (newPoints.length > 0) {
                this.showLoading(true);
                this.certPointDataSvc.create(newPoints)
                    .then((res: CertPoint[]) => {
                        res.map((item: CertPoint) => {
                            this.setState((prevState: ISubTabState) => {
                                const inx = prevState.editPoints.findIndex((x: CertPoint) => x.itemId == item.itemId && x.pointTypeId == item.pointTypeId);
                                if (inx >= 0) {
                                    prevState.editPoints[inx] = item;
                                }
                                return { editPoints: prevState.editPoints, editablePoints: [] }
                            }, () => { this.setState({ points: this.state.editPoints.slice() }) })
                        })
                        this.notifySuccess('Certification points have been successfully updated.');
                        this.showLoading(false);
                    })
                    .catch((err: any) => {
                        this.handleError(err);
                    })
                    .finally(() => {
                        this.pointDataSvc.clearChache();
                        this.scoreSvc.clearChache();
                        this.pointDataSvc.clearChache();
                        this.loadData();
                    })
            }
        }
    }

    findIndexOfType = (data: any[], type: number, id: number, index: number): number => {
        let _index = null;
        let _count: number = -1;
        data.map((point: CertPoint, i: number) => {
            if (point.itemId == id && type == point.pointTypeId && _count < index) {
                _count += 1;
                _index = i;
            }
        })
        return _index;
    }

    changeValue = (item: any, minus: boolean, pointType: PointType, multiplierValue: number = 1, index: number = null): void => {
        this.setState((prevState: ISubTabState) => {

            if (pointType == PointType.Education) {
                const index = prevState.editPoints.findIndex((x: CertPoint) => x.itemId == item.id);
                if (index >= 0) {
                    prevState.editPoints.splice(index, 1);
                } else {
                    const newPoint: CertPoint = {
                        itemId: item.id,
                        enrollmentId: +this.props.enrollmentId,
                        pointTypeId: PointType.Education
                    }
                    prevState.editPoints.push(newPoint);
                }
            }

            if (pointType == PointType.Technical1) {
                const alreadyChange = prevState.editablePoints.find((x: any) => x.id == item.id && x.type == pointType);
                const techPoints = prevState.editPoints.filter((x: CertPoint) => x.itemId == item.id && x.pointTypeId == pointType);
                const techPointIndex = prevState.editPoints.findIndex((x: CertPoint) => x.itemId == item.id && x.pointTypeId == pointType);
                if (!alreadyChange) { prevState.editablePoints.push({ id: item.id, type: pointType, multiplier: multiplierValue }) }

                const newPoint: CertPoint = {
                    multiplier: multiplierValue,
                    itemId: item.id,
                    enrollmentId: +this.props.enrollmentId,
                    pointTypeId: pointType
                }
                if (minus) {
                    if (techPoints.length > 0 && techPointIndex >= 0) { prevState.editPoints.splice(techPointIndex, 1); }
                } else {
                    prevState.editPoints.push(newPoint);
                }
            }

            if (pointType == PointType.Technical2) {

                const alreadyChange = prevState.editablePoints.find((x: any) => x.id == item.id && x.type == pointType);
                const techPoints = prevState.editPoints.filter((x: CertPoint) => x.itemId == item.id && x.pointTypeId == pointType);
                if (!alreadyChange) { prevState.editablePoints.push({ id: item.id, type: pointType, multiplier: multiplierValue }) }

                const indexElement = this.findIndexOfType(prevState.editPoints, pointType, item.id, index);

                if (minus) {
                    const _delIndex = this.findIndexOfType(this.state.newMultiPoints, pointType, item.id, index);
                    if (_delIndex >= 0) {
                        prevState.newMultiPoints.splice(_delIndex, 1);

                        if (prevState.newMultiPoints[_delIndex] && prevState.newMultiPoints[_delIndex].id >= 0) {
                            prevState.dellMultiPoints.push({ id: prevState.newMultiPoints[_delIndex].id });
                        }
                    }

                    if (techPoints.length > 0 && indexElement >= 0) { prevState.editPoints.splice(indexElement, 1); }

                } else {

                    prevState.editPoints.splice(indexElement + 1, 0,
                        {
                            multiplier: multiplierValue,
                            itemId: item.id,
                            enrollmentId: +this.props.enrollmentId,
                            pointTypeId: pointType
                        }
                    );
                    prevState.newMultiPoints.push(
                        {
                            multiplier: multiplierValue,
                            itemId: item.id,
                            enrollmentId: +this.props.enrollmentId,
                            pointTypeId: pointType
                        }
                    );
                }
            }

            if (pointType == PointType.MentorShip1) {
                const alreadyChange = prevState.editablePoints.find((x: any) => x.id == item.id && x.type == pointType);
                const mentorPoints = prevState.editPoints.filter((x: CertPoint) => x.itemId == item.id && x.pointTypeId == pointType);
                const mentorPointIndex = prevState.editPoints.findIndex((x: CertPoint) => x.itemId == item.id && x.pointTypeId == pointType);
                if (!alreadyChange) { prevState.editablePoints.push({ id: item.id, type: pointType, multiplier: multiplierValue }) }

                const newPoint: CertPoint = {
                    multiplier: multiplierValue,
                    itemId: item.id,
                    enrollmentId: +this.props.enrollmentId,
                    pointTypeId: pointType
                }
                if (minus) {
                    if (mentorPoints.length > 0 && mentorPointIndex >= 0) { prevState.editPoints.splice(mentorPointIndex, 1); }
                } else {
                    prevState.editPoints.push(newPoint);
                }
            }

            if (pointType == PointType.MentorShip2) {
                const alreadyChange = prevState.editablePoints.find((x: any) => x.id == item.id && x.type == pointType);
                const mentorPoints = prevState.editPoints.filter((x: CertPoint) => x.itemId == item.id && x.pointTypeId == pointType);
                if (!alreadyChange) { prevState.editablePoints.push({ id: item.id, type: pointType, multiplier: multiplierValue }) }

                const _lastIndex = this.findIndexOfType(prevState.editPoints, pointType, item.id, index);

                if (minus) {
                    const _delIndex = this.findIndexOfType(this.state.newMultiPoints, pointType, item.id, index);
                    if (_delIndex >= 0) { prevState.newMultiPoints.splice(_delIndex, 1); }

                    if (prevState.newMultiPoints[_delIndex] && prevState.newMultiPoints[_delIndex].id >= 0) {
                        prevState.dellMultiPoints.push({ id: prevState.newMultiPoints[_delIndex].id });
                    }

                    if (mentorPoints.length > 0 && _lastIndex >= 0) {
                        prevState.editPoints.splice(_lastIndex, 1);
                    }

                } else {
                    prevState.editPoints.splice(_lastIndex + 1, 0,
                        {
                            multiplier: multiplierValue,
                            itemId: item.id,
                            enrollmentId: +this.props.enrollmentId,
                            pointTypeId: pointType
                        }
                    );
                    prevState.newMultiPoints.push(
                        {
                            multiplier: multiplierValue,
                            itemId: item.id,
                            enrollmentId: +this.props.enrollmentId,
                            pointTypeId: pointType
                        }
                    );
                }
            }
            return { editPoints: prevState.editPoints }
        })
    }

    getGroupPossibleValues = (forMentor: boolean = false): number => {
        let count = 0;
        this.state.data.map((item: Group) => {
            if (forMentor) {
                count += item.pointConfigs[1].maxCount * (item.pointConfigs[1].pointType.value);
            } else {
                count += item.pointConfigs[0].maxCount * (item.pointConfigs[0].pointType.value);
            }
        })
        return count;
    }

    renderTotalSection = (pointConfigs: any[]): React.ReactFragment => {
        return (
            <div className="sections-total-points">
                <Grid
                    spacing={7}
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center">
                    {
                        pointConfigs.map((config: any, i: number) =>
                            <React.Fragment key={i}>

                                {config.pointTypeId == 1 ?
                                    <Grid item xs={12}>
                                        <TotalInfo
                                            typeName={this.state.data[0].pointConfigs[0].pointType.name}
                                            possibleValue={this.getGroupPossibleValues()}
                                            currentValue={this.state.editPoints.filter((x: CertPoint) => x.pointTypeId == 1).length * this.state.data[0].pointConfigs[0].pointType.value}>
                                        </TotalInfo>
                                    </Grid>
                                    :
                                    config.pointTypeId == 2 ?
                                        <Grid item lg={6} xs={12}>
                                            <TotalInfo
                                                typeName={this.state.data[0].pointConfigs[0].pointType.name}
                                                possibleValue={this.getGroupPossibleValues()}
                                                currentValue={this.state.editPoints.filter((x: CertPoint) => x.pointTypeId == 2).length * this.state.data[0].pointConfigs[0].pointType.value}>
                                            </TotalInfo>
                                        </Grid>
                                        :
                                        config.pointTypeId == 3 ?
                                            <Grid item lg={6} xs={12}>
                                                <TotalInfo
                                                    typeName={this.state.data[0].pointConfigs[1].pointType.name}
                                                    possibleValue={this.getGroupPossibleValues(true)}
                                                    currentValue={this.state.editPoints.filter((x: CertPoint) => x.pointTypeId == 3).length * this.state.data[0].pointConfigs[1].pointType.value}>
                                                </TotalInfo>
                                            </Grid>
                                            : null
                                }
                            </React.Fragment>
                        )}
                </Grid>
            </div >
        )
    }

    removeMentors = (item: any, items: CertPoint[]) => {
        this.confirmDeletePoints.item = item;
        this.confirmDeletePoints.items = items;
        this.setState({ confirmObj: this.confirmDeletePoints })
    }

    deletePoints = () => {
        this.confirmDeletePoints.items.map((element: CertPoint) => {
            this.changeValue(this.confirmDeletePoints.item, true, element.pointTypeId);
        })
        this.changeValue(this.confirmDeletePoints.item, true, this.confirmDeletePoints.item.pointConfigs[0].pointTypeId);
        this.setState({ confirmObj: null })
    }

    getPointsItem = (item: any): CertPoint[] => {
        const points: any = {};
        item.pointConfigs.map((config: CertPoint, i: number) => {
            points[config.pointTypeId] = this.state.editPoints.filter((x: CertPoint) => x.pointTypeId == config.pointTypeId && x.itemId == item.id).sort((x: any, b: any) => x.id - b.id);
        })
        return points
    }

    confirmComponent = (confirm: ConfirnObj): React.ReactFragment => {
        if (confirm)
            return (
                <Dialog
                    open={!!confirm}
                    onClose={() => this.setState({ confirmObj: null })}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move', textAlign: 'center', borderBottom: '1px solid #efefef', color: '#828282' }} id="draggable-dialog-title">  {this.state.confirmObj.header}  </DialogTitle>
                    <div className="confirm-message">
                        <DialogContent>
                            <DialogContentText>  {this.state.confirmObj.message}   </DialogContentText>
                        </DialogContent>
                    </div>
                    <div className="confirm-btn">
                        <DialogActions>
                            <Button size="medium" variant="contained" color="primary" onClick={() => this.state.confirmObj.onCancel()} >  Cancel  </Button>
                            <Button size="medium" variant="contained" color="primary" onClick={() => this.state.confirmObj.onOk()} >    OK  </Button>
                        </DialogActions>
                    </div>
                </Dialog>
            )
    }

    // new approach
    changeMultiplier = (value: number, itemId: number, index: number, type: number) => {
        let newArray = this.state.groupState;
        newArray.forEach(x => {
            if (x.itemId == itemId && x.index == index && x.pointTypeId == type) {
                x.multiplier = value;
                x.isMultiplierChanged = true;
            }
        });
        this.setState({ groupState: newArray });
    }

    changeItemWithMultiplier = (checked: boolean, itemId: number, index: number, type: number) => {
        let newArray = this.state.groupState;
        newArray.forEach(x => {
            if (x.itemId == itemId && x.index == index && x.pointTypeId == type) {
                x.isActive = checked;
            }
        });

        if (newArray.filter(x => x.itemId == itemId && x.isActive && x.pointTypeId == 4).length < 3 && newArray.filter(x => x.itemId == itemId && x.isActive && x.pointTypeId == 5).length > 0) {
            let confirmDeleteMentorPoints = {
                itemId: itemId,
                items: [new CertPoint()],
                header: "Information",
                message: "Values of MentorShip will be removed. Are you sure you want to continue?",
                onCancel: () => { this.setState({ confirmObj: null }) },
                onOk: () => { this.deleteMentorPoints(itemId) }
            }
            this.setState({ confirmObj: confirmDeleteMentorPoints })
        };
        this.setState({ groupState: newArray });
    }

    deleteMentorPoints = (itemId: number) => {
        let newArray = this.state.groupState;
        newArray.forEach(x => {
            if (x.itemId == itemId && x.pointTypeId == 5) {
                x.isActive = false;
            }
        });
        this.setState({ groupState: newArray });
        this.setState({ confirmObj: null })
    }

    changeCertPoints = (add: boolean, itemId: number) => {
        let newArray = this.state.groupState;
        if (add && newArray.filter(x => x.itemId == itemId && !x.isActive).length > 0) {
            newArray.filter(x => x.itemId == itemId && !x.isActive)[0].isActive = true;
        } else if (newArray.filter(x => x.itemId == itemId && x.isActive).length > 0) {
            newArray.filter(x => x.itemId == itemId && x.isActive)[0].isActive = false;
        }
        this.setState({ groupState: newArray });
    }

    saveDataNewPUT = () => {
        this.showLoading(true);
        this.state.groupItems.forEach(el => {
            el.points = this.state.groupState.filter(x => x.itemId == el.itemId && x.isActive);
        });
        this.certPointDataSvc.update(this.state.groupItems, null, false)
            .then((res) => {
                this.initializeCurrentGroupState(res);
                this.notifySuccess('Certification points have been successfully updated.');
            })
            .catch((err) => this.notifyError('Failed to update certification points.'))
            .finally(() => {
                this.showLoading(false);
                this.pointDataSvc.clearChache();
                this.scoreSvc.clearChache();
                this.pointDataSvc.clearChache();
            });
    }

    getSumWithMultiplier = (typeId: number) => {
        let total = 0;
        this.state.groupState.filter(x => x.isActive && x.pointTypeId == typeId).forEach(el => {
            total += el.value * el.multiplier;
        })
        return total;
    }

    render() {
        return (
            this.state.data && this.state.data.length > 0 && this.state.data[0].pointConfigs[0].pointTypeId < PointType.Technical2 ?
                <div>
                    <div className="confirm-component"> {this.confirmComponent(this.state.confirmObj)}</div>
                    {this.state.data.map((item: Group, i: number) =>
                        <div key={i} className="mode-item">
                            <ModuleItem
                                readonly={this.state.readonly}
                                description={item.description}
                                pointsConfig={item.pointConfigs}
                                allPoints={this.getPointsItem(item)}
                                onRemove={(delItems: CertPoint[]) => this.removeMentors(item, delItems)}
                                onChange={(minus: boolean, type: PointType, myltiplier?: number, i?: number) => this.changeValue(item, minus, type, myltiplier, i)}
                            />
                        </div>
                    )}

                    {this.renderTotalSection(this.state.data[0].pointConfigs)}
                    {
                        !this.state.readonly ?
                            <CardActions style={{ textAlign: 'right', display: 'block', padding: 15 }}>
                                <Button color="primary" variant="contained" onClick={this.saveData}>Update</Button>
                            </CardActions>
                            :
                            null
                    }
                </div >
                :
                // drawing a group which contains point types with a multiplier(ids: 4, 5, 6, 7)
                this.state.groupState && this.state.groupState.length > 0 && (this.state.pointTypes.some(x => x.id == PointType.Technical2 || x.id == PointType.MentorShip2 || x.id == PointType.MentorShip4 || x.id == PointType.MentorShip3)) ?
                    <div>
                        {this.confirmComponent(this.state.confirmObj)}
                        {/*drawing group items*/}
                        {this.state.data.map((item: Group, i: number) =>
                            <div key={i} className="mode-item">
                                <div className="module-item">
                                    <Grid
                                        spacing={2}
                                        container
                                        direction="row"
                                        justify="space-between"
                                        alignItems="center">
                                        <Grid item lg={this.state.pointTypes.length == 1 ? 6 : 4} xs={12}>
                                            <ItemDescription
                                                title={item.description.text}
                                                description={item.description.description}
                                                referenceUrl={item.description.referenceUrl}>
                                            </ItemDescription>
                                        </Grid>
                                        {/*drawing certification point types*/}
                                        {this.state.pointTypes.map((pointType: PointTypeDescription, y: number) =>
                                            pointType.id != PointType.MentorShip3 ?
                                                <Grid item lg={this.state.pointTypes.length == 1 ? 6 : 4} sm={this.state.pointTypes.length == 1 ? 12 : 6} xs={12} key={y}>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <Typography id="discrete-slider" className={pointType.id == PointType.MentorShip2 ? 'mentorship-type-5-header' : ''} gutterBottom component={'div'}>
                                                            {pointType.name}
                                                            <div className="points-value">{pointType.value}&nbsp;points</div>
                                                            <div className="subtitle-points">Number of Branch Drops</div>
                                                        </Typography>
                                                        {/*drawing certification point items (4, 5, 7 types)*/}
                                                        {this.state.groupState.filter(x => x.itemId == item.id && x.pointTypeId == pointType.id).map((point: CertPoint, j: number) =>
                                                            <PointItemWithMultiplier key={j}
                                                                disableCheckbox={j < this.state.groupState.filter(x => x.itemId == item.id && x.isActive && x.pointTypeId == pointType.id).length - 1 || j > this.state.groupState.filter(x => x.itemId == item.id && x.isActive && x.pointTypeId == pointType.id).length ? true : false}
                                                                disabled={(pointType.id == PointType.MentorShip2 ? this.state.groupState.filter(x => x.itemId == item.id && x.isActive && x.pointTypeId == PointType.Technical2).length < 3 : false) ||
                                                                    this.state.readonly}
                                                                multiplier={point.multiplier || 1}
                                                                isActive={point.isActive}
                                                                index={j}
                                                                itemId={item.id}
                                                                pointTypeId={point.pointTypeId}
                                                                onMultiplierChange={this.changeMultiplier}
                                                                onItemChange={this.changeItemWithMultiplier}>
                                                            </PointItemWithMultiplier>
                                                        )}
                                                    </div>
                                                </Grid>
                                                :
                                                //drawing certification point items (6 type)                                            
                                                <Grid item lg={6} sm={12} xs={12} key={y}>
                                                    <ProgressControl
                                                        pointType={pointType}
                                                        itemId={item.id}
                                                        showButtons={!this.state.readonly}
                                                        points={this.state.groupState.filter(x => x.itemId == item.id && x.pointTypeId == pointType.id)}
                                                        onClickRemove={this.changeCertPoints}
                                                        onClickAdd={this.changeCertPoints} >
                                                    </ProgressControl>
                                                </Grid>
                                        )}
                                    </Grid>
                                </div>
                            </div>
                        )}
                        {/*drawing total section*/}
                        <div className="sections-total-points">
                            <Grid
                                spacing={7}
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="center">
                                {this.state.pointTypes.map((pointType, i: number) =>
                                    <Grid item lg={this.state.pointTypes.length == 1 ? 12 : 6} xs={12} key={i}>
                                        <TotalInfo
                                            typeName={pointType.name}
                                            multiplier={pointType.id == PointType.Technical2 || pointType.id == PointType.MentorShip2 || pointType.id == PointType.MentorShip4 ? 14 : 1}
                                            possibleValue={this.state.groupState.filter(x => x.pointTypeId == pointType.id).length * pointType.value}
                                            currentValue={this.getSumWithMultiplier(pointType.id)}>
                                        </TotalInfo>
                                    </Grid>
                                )}
                            </Grid>
                        </div>
                        {/*drawing action section*/}
                        {!this.state.readonly ?
                            <CardActions style={{ textAlign: 'right', display: 'block', padding: 15 }}>
                                <Button color="primary" variant="contained" onClick={this.saveDataNewPUT}>Update</Button>
                            </CardActions>
                            : null
                        }
                    </div >
                    :
                    null
        )
    }
}