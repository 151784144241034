import { BaseEntityUnDeletable } from "./BaseEntity";
import { TestToken } from "src/quizzer/admin/pins-list.component";

export enum UserRole {
    None = 0,
    TestUser = 10,
    TrackerUser = 20,
    CompanyUser = 50,
    CompanyAdmin = 100,
    GlobalAdmin = 1000
}

export const UserRoleLookup = [ { id: 20, name: 'Tracker User/Learner' }, { id: 50, name: 'Supervisor/Instructor' }, { id: 100, name: 'Company Admin' }, { id: 1000, name: 'Global Admin' }];

export class User extends BaseEntityUnDeletable{
    firstName: string;
    lastName: string;
    email: string;
    role: UserRole;
    login: string;
    password: string;
    companyId: number;
}

export class TestParticipant {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    tokenId: number;
    token: TestToken;
}