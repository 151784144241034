import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Technician from './technician';
import BaseComponent from 'CORE/base-classes/BaseComponent';
import { IProps, IState } from 'CORE/interfaces/main-interfaces';
import DataService from 'CORE/data-service/DataService';
import { Card, IconButton, Container, CardContent } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ModuleGroup, { CertPoint } from './module-group';
import { Group, Certification } from './main-component';

interface IViewContentState extends IState {
    activeSection: number,
    groups: Group[],
    items: Group[],
    nameSection: string;
    activeTab: number;
    levelName: string;
    enrollId: number;
    certId: number;
}

interface IViewContentProps extends IProps {
    setClick: (changeTab: (certificationId: number, enrollId: number, groupId: number, index: number, level: string) => void) => void;
    isDataSaved: (isDataSaved: () => Promise<string>) => Promise<string>;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}


export default class MenuContent extends BaseComponent<IViewContentProps, IViewContentState>{
    private dataSvc: DataService<Certification> = new DataService('certifications/certificates');

    constructor(props: IViewContentProps) {
        super(props);
    }

    getInitialState() {
        return {
            groups: [],
            items: [],
            activeSection: null,
            nameSection: null,
            activeTab: 0,
            levelName: null,
            enrollId: null,

        } as IViewContentState;
    }

    componentDidMount() {
        this.props.setClick(this.changeTab);
        this.props.isDataSaved(this.isDataSaved);

        if (this.props.match && this.props.match.params) {
            this.setState({ enrollId: this.props.match.enrollId })
        }
    }

    isDataSaved = (): Promise<string> => {
        return this.isdataseved();
    }

    isdataseved = (): Promise<string> => new Promise((resolve) => resolve('ok'));

    changeTab = (certId: number, enrollId: number, groupId: number, index: number, levelName: string) => {
        this.setState({
            items: [],
            groups: [],
            activeSection: null,
            activeTab: index,
            levelName,
            enrollId,
            certId,
        }, () => {
            if (groupId > 0) {
                this.getData(certId, groupId);
            }
        })
    }


    getData = (certificationId: number, groupId: number): void => {
        this.showLoading(true);
        const qUrl = `$filter=Id eq ${certificationId}&$expand=Groups($filter=Id eq ${groupId};$expand=Groups, Items($expand=Description,PointConfigs($expand=pointType)))&$select=Groups`;
        this.dataSvc.query({ oDataQuery: qUrl })
            .then((res: any[]) => {
                if (res && res.length > 0 && res[0].groups && res[0].groups.length > 0) {
                    if (res[0].groups[0].items.length > 0) {
                        this.setState({ items: res[0].groups[0].items.sort((a: any, b: any) => a.index - b.index) })
                    }
                    if (res[0].groups[0].groups.length > 0) {
                        this.setState({ groups: res[0].groups[0].groups.sort((a: any, b: any) => a.index - b.index) })
                    }
                    this.setState({ nameSection: `${this.state.levelName} ${res[0].groups[0].name}` })
                }
                this.showLoading(false);
            })
            .catch(err => this.handleError(err));
    }

    setActiveSection = (sectionNumber: number): void => {
        if (sectionNumber == this.state.activeSection) {
            this.setState({ activeSection: null })
        } else {
            this.setState({ activeSection: sectionNumber })
        }
    }

    renderListGroups = (): React.ReactFragment => {
        return (
            this.state.groups.map((group: Group, i: number) =>
                <div key={i} className="removal-section" onClick={() => this.setActiveSection(i)}>
                    {group.name}
                </div>
            )
        )
    }

    renderTitleGroup = (): React.ReactFragment => {
        return (
            <Typography color="textSecondary" variant="h5" component="h2" align="center">
                {this.state.activeSection != null ? this.state.groups[this.state.activeSection].name : this.state.nameSection}
            </Typography>
        )
    }

    backToList = () => {
        this.isDataSaved()
            .then(() => this.setState({ activeSection: null }))
    }

    renderButtonBack = (): React.ReactFragment => {
        return (
            <div className="icon-back">
                <IconButton onClick={() => this.backToList()}>
                    <ArrowBackIcon />
                </IconButton>
            </div>
        )
    }

    getTypePoints = (): number[] => {
        const pointTypes: number[] = [];
        if (this.state.items.length > 0) {
            this.state.items[0].pointConfigs.map((point: CertPoint) => {
                pointTypes.push(point.pointTypeId)
            })
        }
        return pointTypes;
    }

    renderItemsGroup = (isGroup: boolean): React.ReactFragment => {
        return (
            <ModuleGroup
                isDataSaved={(click3: any) => this.isdataseved = click3}
                data={isGroup ? this.state.items : null}
                groupId={isGroup ? null : this.state.groups[this.state.activeSection].id}
                certificationId={isGroup ? this.state.items[0].certificateId : this.state.groups[this.state.activeSection].certificateId}
                enrollmentId={this.state.enrollId}
                typePoints={this.getTypePoints()}
            />
        )
    }


    render() {
        return (
            <div className="certificate-content">
                <TabPanel key={this.state.activeTab} value={this.state.activeTab} index={this.state.activeTab}>
                    {this.state.activeTab > 0 ?
                        <Container maxWidth="md">
                            <Card>
                                <CardContent >
                                    <div className="title-group">
                                        {this.state.activeSection != null ? this.renderButtonBack() : null}
                                        {this.renderTitleGroup()}
                                    </div>
                                    {
                                        this.state.activeSection != null ?
                                            this.renderItemsGroup(false)
                                            :
                                            this.state.groups && this.state.groups.length > 0 ?
                                                this.renderListGroups()
                                                :
                                                this.state.items && this.state.items.length > 0 ? this.renderItemsGroup(true) : null
                                    }
                                </CardContent>
                            </Card>
                        </Container>
                        :
                        <Technician
                            enrollmentId={this.state.enrollId}
                            certificateId={this.state.certId}
                            levelName={this.state.levelName}
                        />
                    }
                </TabPanel>
            </div>
        );
    }
}
