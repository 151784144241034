import { BaseEntity } from "./BaseEntity";
import Certificate from "./Certificate";

export default class UserCertificateStatus extends BaseEntity {
    userId: number;
    direction: string;
    certificateId: number = 0;
    certificate: Certificate;
    certificateChainIndex: number;
    enrollmentId: number;
    isNextAvailable: boolean;
    isCurrenLevel: boolean;
}