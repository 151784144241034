import BaseDataService from "./BaseDataService";
import { ConfigHelper } from "CORE/utils/ConfigHelper";


export class PasswordModel {
    id: number;
    password: string;
    confirmPassword: string;
    rowVersion: string;
}

export default class PasswordService<T> extends BaseDataService{

    constructor(url: string, internalAPI: boolean = false) {
        super(ConfigHelper.getDefaultConfig(url, internalAPI));
    }

    changePsw(obj: PasswordModel): Promise<T> {
        return this.request(this.url, 'PUT', obj)
        .then(res => {
            return res.json();
        })
    }

}
