import React from "react";
import { IProps, IState } from "CORE/interfaces/main-interfaces";
import BaseComponent from "CORE/base-classes/BaseComponent";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Container, Paper, Grid, Button, Typography, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import PasswordService from "CORE/data-service/PasswordService";
import { User } from "CORE/entities/User";
import { UserDetails } from "./admin/users.component";


class IChangePassProps extends IProps {
    user: User | UserDetails;
    onCancel: () => void;
    userUpdated: (user: User) => void;
}

class IChangePassState extends IState {
    isValid: boolean;
    password: string;
    confirmPassword: string;
    showPassRequirements: boolean;
    passRequirements: PassRequirements[];
}



export enum PassRequirementsEnum {
    'Be at least eight characters long',
    'Contains one or more capital letter',
    'Contains one or more numbers',
    'Contains one or more symbol',
}
export enum PassRequirementsTypeEnum {
    'ML',
    'CL',
    'NB',
    'SB',
}

export class PassRequirements {
    active: boolean;
    name: string;
}


export default class ChangePassForm extends BaseComponent<IChangePassProps, IChangePassState> {
    private passSvc: PasswordService<any> = new PasswordService('users/changePassword');

    constructor(props: IChangePassProps) {
        super(props);
    }

    getInitialState() {
        return {
            password: null,
            confirmPassword: null,
            showPassRequirements: false,
            passRequirements: [],
            isValid: true,

        } as IChangePassState
    }

    getRequirements = () => {
        const items: PassRequirements[] = [];
        for (let key in PassRequirementsEnum) {
            if (isNaN(+key)) {
                items.push({ name: key, active: false })
            }
        }
        return items;
    }


    componentDidMount() {
        let _items: PassRequirements[] = [];
        this.setState({ passRequirements: this.getRequirements() }, () => {
            _items = this.state.passRequirements.slice();
        })

        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value != this.state.password) { return false; }
            return true;
        });

        ValidatorForm.addValidationRule('complexity', (value) => {
            _items.map((item: PassRequirements, index: number) => {
                item.active = this.validationRequirements(value, PassRequirementsTypeEnum[index]);
            })
            const _isValid = this.isValidPassword(_items);
            this.setState({ showPassRequirements: false, passRequirements: _items, isValid: _isValid })
            return _isValid;
        });
    }

    isValidPassword = (items: PassRequirements[]): boolean => {
        const a = items.findIndex((x: PassRequirements) => x.active == false);
        return a < 0;
    }


    validationRequirements = (str: string, type: string): boolean => {
        switch (type) {
            case 'ML': return str.length > 7;
            case 'CL': return str.toLocaleLowerCase() != str;
            case 'NB': return /\d/.test(str);
            case 'SB': return /\D/.test(str);
            default: return false;
        }
    }


    handleChange = (name: string) => (event: any) => {
        const value = event.target.value;
        switch (name) {
            case 'password':
                this.setState({ password: value }); return;
            case 'confirmPassword':
                this.setState({ confirmPassword: value }); return;

            default: return
        }
    }

    changePassword = (): void => {
        let passObj = {
            id: this.props.user.id,
            password: this.state.password,
            rowVersion: this.props.user.rowVersion,
            confirmPassword: this.state.confirmPassword
        }
        this.passSvc.changePsw(passObj)
            .then((res: User) => {
                this.setState({ password: null, confirmPassword: null });
                this.notifySuccess('Password has been changed.')
                this.props.userUpdated(res);
            })
            .catch((err: any) => this.handleError(err))
    }

    cancel = (): void => {
        this.setState({ password: null, confirmPassword: null });
        this.props.onCancel();
    }



    render() {

        return (
            <div>
                <div className="change-password-bg">
                    <div className="change-password-main">
                        <Container maxWidth="sm">

                            <ValidatorForm
                                ref="form"
                                onSubmit={() => this.changePassword()}
                            >
                                <Paper elevation={3} style={{ padding: 20 }}>
                                    <Typography color="textSecondary" variant="h6" component="h4" align="center">
                                        Change password <br />
                                        <span className="password-user-name">
                                            for  {this.props.user.firstName}  {this.props.user.lastName}
                                        </span>
                                    </Typography>

                                    <TextValidator
                                        type="password"
                                        style={{ width: '100%' }}
                                        label="New password"
                                        name="repeatPassword"
                                        margin="normal"
                                        value={this.state.password || ''}
                                        onChange={this.handleChange('password')}
                                        validators={['required', 'complexity']}
                                        errorMessages={['this field is required', "Password doesn't meet requirements"]}
                                    />
                                    {
                                        this.state.isValid ? null :
                                            <div className="pass-requirements">
                                                <div className="pass-requirements-title">New password must : </div>
                                                {this.state.passRequirements.map((req: any, i: number) =>
                                                    <div key={i} className="requirement-item">
                                                        <i className={`material-icons ${req.active ? 'color-green' : ''}`}>
                                                            {req.active ? 'check_circle_outline' : 'cancel'}
                                                        </i>
                                                        {req.name}
                                                    </div>
                                                )}
                                            </div>
                                    }

                                    <TextValidator
                                        type="password"
                                        style={{ width: '100%' }}
                                        label="Confirm new password"
                                        name="repeatPassword"
                                        margin="normal"
                                        value={this.state.confirmPassword || ''}
                                        onChange={this.handleChange('confirmPassword')}
                                        validators={['required', 'isPasswordMatch']}
                                        errorMessages={['this field is required', 'Password mismatch']}
                                    />

                                    <Grid
                                        container
                                        direction="row"
                                        justify="flex-end"
                                        alignContent="flex-end"
                                    >
                                        <Button
                                            onClick={() => this.cancel()}
                                            variant="contained"
                                            color="default"
                                            style={{ marginTop: 20, marginLeft: 10 }}
                                        >
                                            Cancel
                                 </Button>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            style={{ marginTop: 20, marginLeft: 10 }}
                                        >
                                            Change
                                 </Button>
                                    </Grid>
                                </Paper>
                            </ValidatorForm>
                        </Container>
                    </div>
                </div>
            </div>
        );
    }
}