import { BaseEntity } from "./BaseEntity";
import { IValidationError, ValidationType, IValidationRule } from "CORE/interfaces/IModelState";

export class CertSetLevelEnrollModel extends BaseEntity {
    userId: number;
    certificateId: number = 0;
    supervisorId: number;
    completionDate?: Date;
    completionGoalDate?: Date;

    validate(): IValidationError[] {
        let errors: IValidationError[] = [];
        errors = this.logErrors(errors, this.validateField('Certificate', this.certificateId, [{ validationType: ValidationType.required } as IValidationRule]));
        errors = this.logErrors(errors, this.validateField('User', this.userId, [{ validationType: ValidationType.required } as IValidationRule]));
        errors = this.logErrors(errors, this.validateField('Supervisor', this.supervisorId, [{ validationType: ValidationType.required } as IValidationRule]));
        return errors;
    }
}

export class CertCreateEnrollModel extends BaseEntity {
    
    certificateId: number;
    userId: number;
    supervisorId: number;
    completionGoalDate?: Date;

    validate(): IValidationError[] {
        let errors: IValidationError[] = [];
        errors = this.logErrors(errors, this.validateField('Certificate', this.certificateId, [{ validationType: ValidationType.required } as IValidationRule]));
        errors = this.logErrors(errors, this.validateField('User', this.userId, [{ validationType: ValidationType.required } as IValidationRule]));
        errors = this.logErrors(errors, this.validateField('Supervisor', this.supervisorId, [{ validationType: ValidationType.required } as IValidationRule]));
        errors = this.logErrors(errors, this.validateField('Completion Goal Date', this.completionGoalDate, [{ validationType: ValidationType.required } as IValidationRule]));
        return errors;
    }
}

export class CertEnrollmentRequiredGroupScore extends BaseEntity {
    userId: number;
    enrollmentId: number;
    groupId: number;
    scoreName: string;
    requiredScore: number;
    score: number;
}