import React, { FunctionComponent, Fragment } from 'react';

type IItemDescriptionProps = {
    title: string,
    description: string,
    referenceUrl: string
}

export const ItemDescription: FunctionComponent<IItemDescriptionProps> = ({ title, description, referenceUrl }) => (
    <Fragment>
        <div className="module-title">
            {title}
        </div>
        <div className="module-subtitle">
            {description}
        </div>
        {referenceUrl ?
            <div className="module-link">
                <a href={`${referenceUrl}`} target="_blank">
                    <i className="material-icons">ondemand_video</i>
                    play video
                </a>
            </div> : null
        }
    </Fragment>
);