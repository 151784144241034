import * as React from 'react';
import MaterialTable, { Column, QueryResult } from 'material-table';
import { EditableListBase, IListProps, IEditableListState } from 'CORE/base-classes/ListBase';
import { BaseEntity } from 'CORE/entities/BaseEntity';
import { IEditableListConfig } from 'CORE/interfaces/IListConfig';
import { FormControl, Select, MenuItem, FormControlLabel, Checkbox, Card, CardContent, Grid, TextField, InputAdornment, Tooltip, IconButton, InputLabel, Dialog, DialogTitle, DialogContentText, DialogContent, Button, DialogActions } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import FilterList from '@material-ui/icons/FilterList';
import Clear from '@material-ui/icons/Clear';
import DataService from 'CORE/data-service/DataService';
import { Company } from './companies.component';
import "../../App.css";
import { IValidationError, ValidationType, IValidationRule } from 'CORE/interfaces/IModelState';
import { UserRole, UserRoleLookup, User } from 'CORE/entities/User';
import SetUserLevel, { Mode } from './user-level.component';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import ChangePassForm from '../change-password-form';
import { ErrorDetails } from 'CORE/base-classes/Error';

interface IUsersProps extends IListProps {
    showTrackerUserRole: boolean;
}

interface IUsersState extends IEditableListState<UserDetails> {
    columns: Column<UserDetails>[];
    email: string;
    validationError: boolean;
    companiesLookup: Company[];
    roleLookup: any[];
    changePassUser: UserDetails;

    createUpdateUser: UserDetails;
    createUpdateUserDialog: boolean;
    createUpdateDialogMode: Mode;
    createUpdateDialogConfirmation: boolean;
    createUpdateDialogConfirmationIsOpened: boolean;
    userLevelCleanCache: boolean;

    filterByFirstName: string;
    filterByLastName: string;
    filterByCompany: number;
    filterByPhone: string;
    filterByEmail: string;
    filterByRole: number;
    filterShowOnlyInactive: boolean;
}

export class UserDetails extends BaseEntity {
    firstName: string;
    lastName: string;
    email: string;
    phone?: string;
    isActive: boolean;
    role: number;
    companyId: number;
    lastSignIn?: Date;
    company: Company;
    actions: UserActions;

    validate(): IValidationError[] {
        let errors: IValidationError[] = [];
        errors = this.logErrors(errors, this.validateField('First Name', this.firstName, [{ validationType: ValidationType.required } as IValidationRule, { validationType: ValidationType.maxLength, params: [250] } as IValidationRule]));
        errors = this.logErrors(errors, this.validateField('Last Name', this.lastName, [{ validationType: ValidationType.required } as IValidationRule, { validationType: ValidationType.maxLength, params: [250] } as IValidationRule]));
        errors = this.logErrors(errors, this.validateField('Phone Number', this.phone, [{ validationType: ValidationType.maxLength, params: [250] } as IValidationRule]));
        errors = this.logErrors(errors, this.validateField('Email', this.email, [{ validationType: ValidationType.required } as IValidationRule, { validationType: ValidationType.email } as IValidationRule, { validationType: ValidationType.maxLength, params: [250] } as IValidationRule]));
        errors = this.logErrors(errors, this.validateField('Role', this.role, [{ validationType: ValidationType.required } as IValidationRule]));
        errors = this.logErrors(errors, this.validateField('Company', this.companyId, [{ validationType: ValidationType.required } as IValidationRule]));
        return errors;
    }
}

export class UserActions {
    setLevel: number[];
}

//ToDo: Disable create/edit capabilities for Supervisor on Test (or remove the page through routing)
export default class UsersComponent extends EditableListBase<UserDetails, IUsersProps, IUsersState>{
    private lookupDataSvc: DataService<Company> = new DataService('companies');

    _isMounted = false;
    returnFirstPage: boolean = false;
    userRole: number = null;

    tableRef: React.RefObject<any>;
    constructor(props: IUsersProps) {
        super(props, { url: 'users', type: UserDetails } as IEditableListConfig<UserDetails>);
        this.tableRef = React.createRef();
        this.take = 10;
    }

    componentDidMount() {
        this._isMounted = true;
        this.lookupDataSvc.query({ filter: 'id gt 2 and isActive eq true', order: 'name asc', oDataQuery: '&$select=id,name' }).then(res => {
            if (res) {
                if (this._isMounted) {
                    this.setState({ companiesLookup: res });
                }
            }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    authCompleted() {
        if (this.user.id) {
            if (this._isMounted) {
                this.setRoleLookup(this.user.role);
            }
        }
    }

    filterData = () => {
        this.skip = 0;
        this.tableRef.current;
        this.tableRef.current.onQueryChange({ page: 0 });
        const cancelBtn: any = document.querySelectorAll('[title="Cancel"]')[0];
        if (cancelBtn) { cancelBtn.click(); }
    }

    updateUserPassword = (user: UserDetails) => {
        const _data = this.state.data;
        const index = this.state.data.findIndex((x: UserDetails) => x.id == user.id);
        if (index >= 0) {
            _data[index].rowVersion = user.rowVersion;
            this.setState({ data: _data, changePassUser: null })
        }
    }

    getInitialState() {
        return {
            data: [],
            columns: [
                {
                    title: 'First Name', field: 'firstName', customSort: () => 0
                },
                {
                    title: 'Last Name', field: 'lastName', customSort: () => 0
                },
                {
                    title: 'Phone', field: 'phone', customSort: () => 0
                },
                {
                    title: 'Email', field: 'email'
                },
                {
                    title: 'Role', field: 'role', customSort: () => 0,
                    render: rowData => UserRoleLookup.filter(x => x.id == rowData.role).length > 0 ? UserRoleLookup.filter(x => x.id == rowData.role)[0].name : 'Tracker User',
                },
                {
                    title: 'Company', field: 'companyId', hidden: this.user.role < UserRole.GlobalAdmin, customSort: () => 0,
                    render: rowData => rowData.companyId == 1 ? '-' : rowData.company.name
                },
                { title: 'Last Sign In', field: 'lastSignIn', filtering: false, customSort: () => 0, editable: 'never', initialEditValue: null, render: rowData => (rowData && rowData.lastSignIn ? new Date(rowData.lastSignIn).toLocaleDateString() : '-') },
                {
                    title: 'Active', field: 'isActive', type: 'boolean', customSort: () => 0,
                    render: rowData => rowData.isActive || rowData.isActive === undefined ? <CheckBox className='grean-icon' /> : <CheckBoxOutlineBlank className='grey-icon' />
                }
            ],
            companiesLookup: [],
            roleLookup: [],
            filterByFirstName: '',
            filterByLastName: '',
            filterByCompany: 0,
            filterByPhone: '',
            filterByEmail: '',
            filterByRole: 0,
            filterShowOnlyInactive: false,
            changePassUser: null,

            createUpdateUser: new UserDetails(),
            createUpdateUserDialog: false,
            createUpdateDialogMode: Mode.View,
            createUpdateDialogConfirmation: false,
            createUpdateDialogConfirmationIsOpened: false,
            userLevelCleanCache: false
        } as IUsersState;
    }

    setRoleLookup(role: UserRole) {
        let roleLookup = UserRoleLookup;
        if (!this.props.showTrackerUserRole)
            roleLookup = roleLookup.filter(x => x.id != UserRole.TrackerUser);
        switch (role) {
            case UserRole.GlobalAdmin:
                this.setState({ roleLookup: roleLookup });
                break;
            case UserRole.CompanyAdmin:
                this.setState({ roleLookup: roleLookup.filter(x => x.id <= UserRole.CompanyAdmin && x.id >= UserRole.TrackerUser) });
                break;
            case UserRole.CompanyUser:
                this.setState({ roleLookup: roleLookup.filter(x => x.id <= UserRole.CompanyAdmin && x.id >= UserRole.TrackerUser) });
                break;
            default:
                this.setState({ roleLookup: [] });
                break;
        }
    }

    buildFilter() {
        let filterItems = [];
        filterItems.push(this.state.filterShowOnlyInactive ? 'isActive eq false' : 'isActive eq true');
        filterItems.push(this.state.filterByFirstName ? `contains(FirstName, '${this.state.filterByFirstName}')` : null);
        filterItems.push(this.state.filterByLastName ? `contains(LastName, '${this.state.filterByLastName}')` : null);
        filterItems.push(this.state.filterByPhone ? `contains(Phone, '${this.state.filterByPhone}')` : null);
        filterItems.push(this.state.filterByEmail ? `contains(Email, '${this.state.filterByEmail}')` : null);
        filterItems.push(this.state.filterByRole ? `Role eq '${this.state.filterByRole}'` : null);
        filterItems.push(!this.props.showTrackerUserRole ? `Role ne '${UserRole.TrackerUser}'` : null);
        filterItems.push(this.state.filterByCompany ? `companyId eq ${this.state.filterByCompany}` : null);

        this.filterStr = filterItems.filter(x => x !== null).join(' and ');

    }

    clearFilter() {
        this.setState({
            filterByFirstName: '',
            filterByLastName: '',
            filterByPhone: '',
            filterByEmail: '',
            filterByRole: 0,
            filterByCompany: 0,
            filterShowOnlyInactive: false
        });
    }

    getOrderFields(fieldName: string) {
        switch (fieldName) {
            case 'companyId':
                return 'company/name'
            default:
                return fieldName;
        }
    }

    onError(e: ErrorDetails): boolean {
        if (e.detail && e.detail == 'Uniqueness violation.') {
            const errFields: string[] = [];
            if (e.Fields) {
                e.Fields.map((errEl: string, i: number) => {
                    errFields.push(`${errEl}`);
                })
                this.notifyError(`${e.detail.slice(0, -1)} of ${e.Fields && e.Fields.length > 1 ? 'these' : 'this'} field${e.Fields && e.Fields.length > 1 ? 's' : ''} : ${errFields}.`)
                return true;
            } else {
                return false;
            }
        }
        return false;
    }

    changeUserStateValue = (value: any, property: string) => {
        const _data: any = Object.assign({}, this.state.createUpdateUser)
        _data[property] = value;
        this.setState({ createUpdateUser: _data })
    }

    updateUserCertificats = (value: number[]) => {
        //ToDo: create a copy from .state.createUpdateUser.actions
        //let newActions = [...this.state.createUpdateUser.actions];
        let newActions = new UserActions();
        newActions.setLevel = value;
        this.changeUserStateValue(newActions, "actions")
    }

    createUserObject(mode: Mode): UserDetails {
        let newUser = JSON.parse(JSON.stringify(this.state.createUpdateUser))
        newUser = this.trimAllStrings(newUser);
        if (newUser.role == UserRole.TrackerUser) {
            if (!newUser.actions || !newUser.actions.setLevel || newUser.actions.setLevel.length == 0) {
                this.notifyWarning("Tracker user should have at least one direction and level");
                return null;
            }
            if (!newUser.actions || !newUser.actions.setLevel || newUser.actions.setLevel.filter((x: number) => x == null).length > 0) {
                this.notifyWarning("Certificate level couldn't not be empty for active direction");
                return null;
            }
        }
        if (this.user.role <= UserRole.CompanyAdmin) {
            newUser.companyId = this.user.companyId;            
        }
        // if (!newUser.role && this.user && this.user.role == UserRole.CompanyUser && this.props.showTrackerUserRole){
        //     newUser.role = UserRole.TrackerUser;
        // }
        if (newUser.role == UserRole.GlobalAdmin) {
            newUser.companyId = 1;
        }
        if (mode == Mode.Create)
            newUser.isActive = true;
        else {
            newUser.phone = newUser.phone || null;
            if (this.user.role == UserRole.GlobalAdmin) {
                newUser.actions = null;
            }
        }
        if (!this.validateModel(newUser)) {
            return null;
        }
        if (this._isMounted) {
            this.setState({ newItem: newUser });
        }
        return newUser;
    }

    createUser = () => {
        new Promise((resolve, reject) => {
            setTimeout(() => {
                let newUser = this.createUserObject(Mode.Create);
                if (newUser == null) return reject();

                this.create()
                    .then(() => {
                        this.cleanCache();
                        this.filterData();
                        this.setState({ userLevelCleanCache: true });
                        resolve();
                    }).catch((err) => {
                        reject(err);
                    }).finally(() => {
                        this.setState({ createUpdateUserDialog: false, createUpdateDialogConfirmation: false, createUpdateDialogConfirmationIsOpened: false });
                    });
            }, 100);
        })
    }

    updateUser = () => {
        if (this.state.createUpdateDialogConfirmation && !this.state.createUpdateDialogConfirmationIsOpened) {
            this.setState({ createUpdateDialogConfirmationIsOpened: true });
            return;
        }
        new Promise((resolve, reject) => {
            setTimeout(() => {
                let newUser = this.createUserObject(Mode.Edit);
                if (newUser == null) return reject();

                this.update(newUser, false)
                    .then(() => {
                        this.cleanCache();
                        this.filterData();
                        this.setState({ userLevelCleanCache: true });
                        resolve();
                    }).catch(err => {
                        reject(err);
                    }).finally(() => {
                        this.setState({ createUpdateUserDialog: false, createUpdateDialogConfirmation: false, createUpdateDialogConfirmationIsOpened: false });
                    });
            }, 100);
        })
    }

    getEmptyUser = (): UserDetails => {
        let usr = new UserDetails();
        if(this.user && this.user.role == UserRole.CompanyUser && this.props.showTrackerUserRole){
            usr.role = UserRole.TrackerUser;
        }
        return usr;
    }

    render() {
        return (
            <div className="admin-table">
                <Dialog
                    open={!!this.state.createUpdateUserDialog}
                    aria-labelledby="form-dialog-title"
                    className="create-user-form"
                    fullWidth
                    maxWidth="lg">
                    <DialogTitle id="form-dialog-title">{this.state.createUpdateDialogMode == Mode.Create ? 'Create User' : 'Edit User'}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={3} direction="row" alignItems="center" className={this.state.createUpdateDialogConfirmationIsOpened ? 'hidden' : ''}>
                            <Grid item lg={4} sm={6} xs={12}>
                                <TextField id="firstNameId" label="First Name" autoComplete='nope' fullWidth
                                    value={this.state.createUpdateUser.firstName || ""}
                                    onChange={e => this.changeUserStateValue(e.target.value, "firstName")}
                                />
                            </Grid>
                            <Grid item lg={4} sm={6} xs={12}>
                                <TextField id="lastNameId" label="Last Name" autoComplete='nope' fullWidth
                                    value={this.state.createUpdateUser.lastName || ""}
                                    onChange={e => this.changeUserStateValue(e.target.value, "lastName")}
                                />
                            </Grid>
                            <Grid item lg={4} sm={6} xs={12}>
                                <TextField id="phone" label="Phone" autoComplete='nope' fullWidth
                                    value={this.state.createUpdateUser.phone || ""}
                                    onChange={e => this.changeUserStateValue(e.target.value, "phone")}
                                />
                            </Grid>
                            <Grid item lg={4} sm={6} xs={12}>
                                <TextField id="emailId" label="Email" autoComplete='nope' type="email" fullWidth
                                    value={this.state.createUpdateUser.email || ""}
                                    onChange={e => this.changeUserStateValue(e.target.value, "email")}
                                />
                            </Grid>
                            <Grid item lg={4} sm={6} xs={12}>
                                <FormControl className="select-role">
                                    <InputLabel shrink htmlFor="role-label-placeholder">
                                        Role
                                    </InputLabel>
                                    <Select
                                        disabled={this.user && this.user.role == UserRole.CompanyUser}
                                        name="role"
                                        fullWidth
                                        value={this.state.createUpdateUser.role || null } // || (this.user && this.user.role == UserRole.CompanyUser ? UserRole.TrackerUser : null)
                                        onChange={(e) => {
                                            this.changeUserStateValue(e.target.value, "role");
                                            // setTimeout(() => {
                                            //     if (e.target.value == UserRole.GlobalAdmin) {
                                            //         this.changeUserStateValue(1, "companyId")
                                            //     }
                                            //     else {
                                            //         this.changeUserStateValue(0, "companyId")
                                            //     }
                                            // }, 100);

                                        }}
                                    >{this.state.roleLookup.map((el: any, i: number) => <MenuItem key={i} value={el.id}>{el.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item lg={4} sm={6} xs={12} className={this.user.role < UserRole.GlobalAdmin ? "hidden" : ""}>
                                <FormControl

                                    disabled={this.state.createUpdateUser.role == UserRole.GlobalAdmin}
                                >
                                    <InputLabel shrink htmlFor="company-label-placeholder">
                                        Company
                                    </InputLabel>
                                    <Select
                                        name="company"
                                        fullWidth
                                        value={this.state.createUpdateUser.companyId || ''}
                                        onChange={(e) => this.changeUserStateValue(e.target.value, "companyId")}
                                    >{this.state.companiesLookup.map((el: any, i: number) => <MenuItem key={i} value={el.id}>{el.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item lg={4} sm={6} xs={12} className={this.state.createUpdateDialogMode != Mode.Edit ? 'hidden' : ''}>

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.createUpdateUser.isActive || false}
                                            onChange={(e: any) => this.changeUserStateValue(e.target.checked, "isActive")}
                                            value="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label="Is Active"
                                />
                            </Grid>
                            <Grid item lg={12} sm={12} xs={12} className={this.state.createUpdateUser.role != UserRole.TrackerUser ? "hidden" : ""}>
                                <SetUserLevel
                                    userToSetLevel={this.state.createUpdateDialogMode != Mode.Create ? this.state.createUpdateUser : null}
                                    mode={this.state.createUpdateDialogMode}
                                    onChange={this.updateUserCertificats}
                                    cleanCache={this.state.userLevelCleanCache}
                                    cleanCacheFinished={() => this.setState({ userLevelCleanCache: false })}
                                    disabled={this.user.role == UserRole.GlobalAdmin}
                                    showConfirmation={(show) => this.setState({ createUpdateDialogConfirmation: show })}
                                ></SetUserLevel>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} direction="row" alignItems="center" className={!this.state.createUpdateDialogConfirmationIsOpened ? 'hidden' : ''}>
                            <Grid item lg={12} sm={12} xs={12}>
                                <b>Caution</b><br /><br />
                                You manually have changed a user level on the previous screen, all records regarding previous user certification will be removed.<br />
                                Please note, this action cannot be undone. <br /><br />
                                <b>Are you sure you want to proceed?</b>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="default" onClick={() => this.setState({ createUpdateUserDialog: false, createUpdateUser: new UserDetails() })}>
                            Cancel
                    </Button>
                        <Button className={this.state.createUpdateDialogMode != Mode.Create || this.state.createUpdateDialogConfirmationIsOpened ? 'hidden' : ''} variant="contained" color="primary" onClick={() => this.createUser()}  >
                            Create
                    </Button>
                        <Button className={!this.state.createUpdateDialogConfirmationIsOpened ? 'hidden' : ''} variant="contained" color="primary" onClick={() => this.setState({ createUpdateDialogConfirmationIsOpened: false })} >
                            Back
                    </Button>
                        <Button variant="contained" color="primary" onClick={() => this.updateUser()} className={this.state.createUpdateDialogMode != Mode.Edit ? 'hidden' : ''}>
                            {this.state.createUpdateDialogConfirmationIsOpened ? 'Proceed' : 'Update'}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Card>
                    <CardContent>
                        <div className="admin-filter-wrapper">
                            <Grid container spacing={3} direction="row" alignItems="center">
                                <Grid item lg={2} sm={4} xs={6}>
                                    <TextField label="First Name" autoComplete='nope'
                                        value={this.state.filterByFirstName}
                                        onChange={e => this.setState({ filterByFirstName: e.target.value })} onKeyPress={(e) => { if (e.key === 'Enter') { (this.filterData()) } }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <FilterList />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={2} sm={4} xs={6}>
                                    <TextField label="Last Name" autoComplete='nope'
                                        value={this.state.filterByLastName}
                                        onChange={e => this.setState({ filterByLastName: e.target.value })} onKeyPress={(e) => { if (e.key === 'Enter') { (this.filterData()) } }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <FilterList />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={2} sm={4} xs={6}>
                                    <TextField id="phone-filter" label="Phone" autoComplete='nope'
                                        value={this.state.filterByPhone}
                                        onChange={e => this.setState({ filterByPhone: e.target.value })} onKeyPress={(e) => { if (e.key === 'Enter') { (this.filterData()) } }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <FilterList />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={2} sm={4} xs={6}>
                                    <TextField id="email-filter" label="Email" autoComplete='nope'
                                        value={this.state.filterByEmail}
                                        onChange={e => this.setState({ filterByEmail: e.target.value })} onKeyPress={(e) => { if (e.key === 'Enter') { (this.filterData()) } }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <FilterList />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                {
                                    this.user.role == UserRole.GlobalAdmin ?
                                        <Grid item lg={2} sm={4} xs={6}>
                                            <FormControl >
                                                <InputLabel htmlFor="company-filter">Company</InputLabel>
                                                <Select className="admin-filter-company-dl" inputProps={{ name: 'company', id: 'company-filter', }}
                                                    value={this.state.filterByCompany}
                                                    onChange={e => { this.setState({ filterByCompany: e.target.value as number }); (this.filterData()) }}
                                                >{this.state.companiesLookup.map((el: any, i: number) => <MenuItem key={i} value={el.id}>{el.name}</MenuItem>)}
                                                </Select>
                                                {
                                                    this.state.filterByCompany ? <IconButton aria-label="delete" className="select-clear-button" size="small" onClick={() => { this.setState({ filterByCompany: 0 }); (this.filterData()) }}>
                                                        <Clear fontSize="inherit" />
                                                    </IconButton> : ''
                                                }
                                            </FormControl>
                                        </Grid> : ''
                                }
                                <Grid item lg={2} sm={4} xs={6}>
                                    <FormControl >
                                        <InputLabel htmlFor="role-filter">Role</InputLabel>
                                        <Select className="admin-filter-role-dl" inputProps={{ name: 'role', id: 'role-filter', }}
                                            value={this.state.filterByRole}
                                            onChange={e => { this.setState({ filterByRole: e.target.value as number }); (this.filterData()) }}
                                        >{this.state.roleLookup.map((el: any, i: number) => <MenuItem key={i} value={el.id}>{el.name}</MenuItem>)}
                                        </Select>
                                        {
                                            this.state.filterByRole ? <IconButton aria-label="delete" className="select-clear-button" size="small" onClick={() => { this.setState({ filterByRole: 0 }); (this.filterData()) }}>
                                                <Clear fontSize="inherit" />
                                            </IconButton> : ''
                                        }
                                    </FormControl>
                                </Grid>
                                <Grid item lg={2} sm={6} xs={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.filterShowOnlyInactive || false}
                                                onChange={(e) => { this.setState({ filterShowOnlyInactive: e.target.checked }); (this.filterData()); }}
                                                value="checkedB"
                                                color="primary"
                                            />
                                        }
                                        label="Show Only Inactive"
                                        classes={{ label: 'filter-checkbox-label' }}
                                    />
                                </Grid>
                                <Grid item lg={this.user.role == UserRole.GlobalAdmin ? 10 : 12} sm={6} xs={12} className="admin-filter-buttons">
                                    <Tooltip title="Run Filter" aria-label="runFilter">
                                        <IconButton aria-label="runFilter" onClick={() => { this.filterData() }}>
                                            <SearchIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Clear Filter" aria-label="clearFilter">
                                        <IconButton aria-label="clearFilter" onClick={() => { if (this._isMounted) { this.clearFilter(); } (this.filterData()); }}>
                                            <Clear />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </div>
                    </CardContent>
                </Card>
                <div className="admin-table-material">
                    <MaterialTable
                        tableRef={this.tableRef}
                        columns={this.state.columns}
                        data={query =>
                            new Promise((resolve, reject) => {
                                this.skip = this.returnFirstPage ? 0 : query.page * query.pageSize;
                                this.returnFirstPage = false;
                                this.take = query.pageSize;
                                this.order = query.orderBy ? `${this.getOrderFields(query.orderBy.field)} ${query.orderDirection}` : 'id asc';
                                this.includes = "Company";
                                if (this._isMounted) {
                                    this.buildFilter();
                                }
                                this.getData().then((res) => {
                                    resolve(res as QueryResult<UserDetails>);
                                }).catch(() => reject())
                            })}
                        title="Users"
                        options={{
                            initialPage: (this.skip / this.take),
                            pageSize: this.take,
                            pageSizeOptions: [10, 25, 50],
                            showEmptyDataSourceMessage: true,
                            debounceInterval: 1000,
                            actionsColumnIndex: -1,
                            addRowPosition: "first",
                            search: false,
                            detailPanelType: 'single',
                            rowStyle: data => data.role === UserRole.TrackerUser ? { zIndex: 1 } : { zIndex: 2 }
                        }}

                        detailPanel={[
                            {
                                disabled: !this.props.showTrackerUserRole,
                                tooltip: 'View User Current Level',
                                render: rowData => {
                                    return (
                                        <SetUserLevel
                                            userToSetLevel={rowData}
                                            mode={Mode.View}
                                            onChange={this.updateUserCertificats}
                                            cleanCache={this.state.userLevelCleanCache}
                                            cleanCacheFinished={() => this.setState({ userLevelCleanCache: false })}                                           
                                        >
                                        </SetUserLevel>
                                    )
                                },
                            }
                        ]}
                        actions={[
                            {
                                icon: 'lock_open',
                                tooltip: 'Change password',
                                onClick: (event, rowData) => {
                                    this.setState({ changePassUser: rowData as UserDetails })
                                },
                            },
                            {
                                icon: 'edit',
                                tooltip: 'Edit User',
                                onClick: (event, rowData) => this.setState({ createUpdateUserDialog: true, createUpdateDialogMode: Mode.Edit, createUpdateUser: rowData as UserDetails, createUpdateDialogConfirmation: false, createUpdateDialogConfirmationIsOpened: false })
                            },
                            {
                                icon: 'add_box',
                                tooltip: 'Add User',
                                isFreeAction: true,
                                hidden: this.user.role === UserRole.CompanyUser && !this.props.showTrackerUserRole,
                                onClick: (event) => this.setState({ createUpdateUserDialog: true, createUpdateDialogMode: Mode.Create, createUpdateUser: this.getEmptyUser(), createUpdateDialogConfirmation: false, createUpdateDialogConfirmationIsOpened: false })
                            }
                        ]}
                    />
                </div>
                {
                    this.state.changePassUser ?
                        <ChangePassForm
                            user={this.state.changePassUser}
                            userUpdated={(user: any) => this.updateUserPassword(user)}
                            onCancel={() => this.setState({ changePassUser: null })}
                        />
                        : null
                }
            </div >
        )
    }
}