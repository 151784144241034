import BaseComponent from 'CORE/base-classes/BaseComponent';
import DataService from 'CORE/data-service/DataService';
import { IState, IProps } from 'CORE/interfaces/main-interfaces';
import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MenuContent from './menu-content';
import { BaseEntity } from 'CORE/entities/BaseEntity';

// import { NavLink, Link } from 'react-router-dom';

interface ITechState extends IState {
    activeTabId: number;
    menuItems: Group[];
    levelName: string;
    certificationId: number;
}

export class Group extends BaseEntity {
    items?: Group[];
    groups?: Group[];
    descriptionId: number;
    description: Description;
    index: number;
    parentId: number;
    certificateId: number;
    pointConfigs?: any;
    mentorValue?: number;
    teachValue?: number;
}

export class Description {
    id: number;
    description: string;
    referenceUrl: string;
    text: string;
}

export class Certification extends BaseEntity {
    groups: Group[];
    isActive: boolean;
    path: string;
    preRequiredId: number;
    requiredScore: number;
}

export class IEnrollProps extends IProps {
    history: any;
    location: any;
}


export default class MainComponent extends BaseComponent<IEnrollProps, ITechState> {
    private dataSvc: DataService<Certification> = new DataService('certifications/certificates');

    constructor(props: IEnrollProps) {
        super(props)
    }

    getInitialState() {
        return {
            activeTabId: 0,
            menuItems: [],
            levelName: null,
        } as ITechState;
    }



    handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.isDataSaved()
            .then((res: string) => {
                if (res == 'ok') {
                    this.setState({ activeTabId: this.state.menuItems[newValue].index }, () => {
                        this.changeTab(
                            this.props.match.params.certId,
                            this.props.match.params.enrollId,
                            this.state.menuItems[this.state.activeTabId].id,
                            this.state.menuItems[newValue].index,
                            this.state.levelName);
                    })
                }
            })
    }

    isDataSaved = (): Promise<string> => new Promise((resolve) => resolve('')); 

    changeTab = (certificationId: number, enrollId: number, groupId: number, index: number, level: string) => { }

    a11yProps = (index: number): object => {
        return {
            id: `scrollable-force-tab-${index}`,
            'aria-controls': `scrollable-force-tabpanel-${index}`,
        };
    }

    componentDidMount() {
        if (this.props.match && this.props.match.params) {
            if (!isNaN(this.props.match.params.certId)) {
                this.getData(this.props.match.params.certId);

            } else {
                // location.replace('/');
                const { from } = this.props.location.state || { from: { pathname: "/" } };
                this.props.history.push(from);
            }
        }
    }

    getData = (id: number): void => {
        if (this.props.match.params.enrollId != 0) {
            this.showLoading(true);
            this.dataSvc.query({ oDataQuery: `$filter=Id eq ${id}&$expand=Groups($filter=ParentId eq null;$orderby=Index)&$select=Groups,name` })
                .then((res: Certification[]) => {
                    let newMenuItems: any = [{ name: 'Technician', id: 0, index: 0 },];
                    if (res && res.length > 0 && res[0].groups) {
                        newMenuItems.push(...res[0].groups.sort((a: Group, b: Group) => a.index - b.index))
                    }
                    this.setState({ menuItems: newMenuItems, levelName: res[0].name });
                    this.changeTab(this.props.match.params.certId, this.props.match.params.enrollId, null, null, res[0].name);
                    this.showLoading(false);
                })
                .catch(err => this.handleError(err));
        }
    }

    render() {

        return (
            <div>
                {/* <div className="btn-back">
                    <NavLink to={`/enrollments`} >
                        <IconButton >
                            <ArrowBackIcon />
                        </IconButton>
                    </NavLink>
                </div> */}

                {/* top menu___________________________ */}
                <AppBar position="static" color="default">
                    <Tabs className="certificate-tabs"
                        value={this.state.activeTabId}
                        onChange={this.handleChange}
                        variant="scrollable"
                        scrollButtons="on"
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="scrollable force tabs example" >
                        {this.state.menuItems.map((item: Group, index: number) =>
                            <Tab key={index} label={item.name} {...this.a11yProps(item.index)} />
                        )}
                    </Tabs>
                </AppBar>

                {/* content__________________________________  */}
                <MenuContent
                    isDataSaved={(click2: any) => this.isDataSaved = click2}
                    setClick={click => this.changeTab = click}
                />
            </div>
        )
    }


}