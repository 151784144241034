import * as React from 'react';
import PropTypes from "prop-types";
import { Container, AppBar, Tabs, Tab, Tooltip } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Auth } from 'CORE/utils/Auth';
import { UserRole, User } from 'CORE/entities/User';
import { Link } from 'react-router-dom';

function TabContainer({ children, dir }: any) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired
};

export interface AdminTab {
  title: string;
  tooltip: string;
  routerLink: string;
  role: UserRole;
  icon: JSX.Element;
}

interface IMenuState {
  value: number;
  currentUser: User;
  tabsCount: number;
}

interface IMenuProps {
  history: any;
  location: Location;
  menuItems: AdminTab[];
}

export default class AppBarMenu extends React.Component<IMenuProps, IMenuState>{

  constructor(props: IMenuProps) {
    super(props);

    const currentUser = Auth.getProvider().Identity();

    let tabsToDisplay = this.props.menuItems.filter(x => x.role <= currentUser.role);
    let currentTabIndex = tabsToDisplay.findIndex(x => this.props.location.pathname.includes(x.routerLink));

    if (currentTabIndex < 0 && tabsToDisplay.length > 0) {
      this.props.history.push(tabsToDisplay[0].routerLink)
    }

    this.state = {
      value: currentTabIndex > -1 ? currentTabIndex : 0,
      currentUser: currentUser,
      tabsCount: tabsToDisplay.length
    } as IMenuState
  }

  handleChange = (event: React.ChangeEvent<{}>, value: number) => {
    this.setState({ value });
  }

  handleChangeIndex = (index: number) => {
    this.setState({ value: index });
  };

  componentDidUpdate(prevProps: any) {
    if (this.props.location !== prevProps.location) {
      const currentUser = Auth.getProvider().Identity();
      let tabsToDisplay = this.props.menuItems.filter(x => x.role <= currentUser.role);
      let currentTabIndex = tabsToDisplay.findIndex(x => this.props.location.pathname.includes(x.routerLink));
      this.setState({ value: currentTabIndex });
      if (currentTabIndex >= 0 && tabsToDisplay.length > 0) {
        this.setState({ value: currentTabIndex });
      }
    }
  }

  render() {
    return (
      <div className="admin-container">
        <Container maxWidth="xl" >
          <AppBar position="static" color="default">
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              width="full"
              scrollButtons="on"
              className={`wrapper-for-${this.state.tabsCount}-tab`}
            >{
                this.props.menuItems.filter(x => x.role <= this.state.currentUser.role)
                  .map((el, i) =>
                    <Tooltip title={el.tooltip} key={i}>
                      <Tab className="admin-tabs" label={el.title} icon={el.icon} component={Link} to={el.routerLink} />
                    </Tooltip>
                  )
              }
            </Tabs>
          </AppBar>
          {this.props.children}
        </Container>
      </div>
    )
  }
}