import * as React from 'react';
import MaterialTable, { Column, QueryResult } from 'material-table';
import { EditableListBase, IListProps, IEditableListState } from 'CORE/base-classes/ListBase';
import { BaseEntity } from 'CORE/entities/BaseEntity';
import { IEditableListConfig } from 'CORE/interfaces/IListConfig';
import { User, UserRole } from 'CORE/entities/User';
import { FormControl, MenuItem, Select, CardContent, Card, Grid, InputLabel, IconButton, Tooltip, FormControlLabel, Checkbox, Paper, TextField, InputAdornment } from '@material-ui/core';
import DataService from 'CORE/data-service/DataService';
import { IValidationError, IValidationRule, ValidationType } from 'CORE/interfaces/IModelState';
import UserCertificateStatus from 'CORE/entities/UserCertificateStatus';
import Certificate from 'CORE/entities/Certificate';
import CertEnrollmentScore from './enrollment-score';
import { Clear, Search, FilterList } from '@material-ui/icons';
import { KeyboardDatePicker } from '@material-ui/pickers';
import AutoComplete from '../common/autocomplete';
import { forkJoin } from 'rxjs';
import { ErrorDetails } from 'CORE/base-classes/Error';


interface ICertEnrolmentsProps extends IListProps {
    history: any;
    location: any;
}

export class CertEnrolments extends BaseEntity {
    userId: number;
    user: User;
    certificateId: number;
    certificate: Certificate;
    enrolledDate: Date;
    completionGoalDate: Date;
    completionDate: Date;
    thresholdReachedDate: Date;
    supervisorId: number;
    supervisor: User;
    type: number;
    isApproved: boolean;
    isReadyForApproval: boolean;
    status: string = "";

    validate(): IValidationError[] {
        let errors: IValidationError[] = [];
        errors = this.logErrors(errors, this.validateField('User', this.userId, [{ validationType: ValidationType.required } as IValidationRule]));
        errors = this.logErrors(errors, this.validateField('Certificate', this.certificateId, [{ validationType: ValidationType.required } as IValidationRule]));
        errors = this.logErrors(errors, this.validateField('Completion Goal Date', this.completionGoalDate, [{ validationType: ValidationType.required } as IValidationRule]));
        errors = this.logErrors(errors, this.validateField('Supervisor', this.supervisorId, [{ validationType: ValidationType.required } as IValidationRule] ));
        return errors;
    }
}

interface ICertEnrolmentsState extends IEditableListState<CertEnrolments> {
    columns: Column<CertEnrolments>[];
    usersLookup: User[];
    availableCertificates: UserCertificateStatus[];
    certificatesLookup: Certificate[];
    filterByUser: string;
    filterByCertification: number;
    filterBySupervisor: string;
    filterShowReadyForApprovalOnly: boolean;
    oneRecord: boolean;
}


export default class CertEnrolmentsComponent extends EditableListBase<CertEnrolments, ICertEnrolmentsProps, ICertEnrolmentsState>{

    private userLookupDataSvc: DataService<User> = new DataService('users');
    private availableCertLookupDataSvc: DataService<UserCertificateStatus> = new DataService('certifications/certificates/userStatuses');
    private certLookupDataSvc: DataService<Certificate> = new DataService('certifications/certificates');
    private approveDataSvc: DataService<CertEnrolments> = new DataService('certificationEnrollments/approve');

    _isMounted = false;
    returnFirstPage: boolean = false;
    tableRef: React.RefObject<any>;
    isRenderedOnAdmin = this.props.location.pathname.includes('/admin/');

    constructor(props: ICertEnrolmentsProps) {
        super(props, { url: 'certificationEnrollments', type: CertEnrolments } as IEditableListConfig<CertEnrolments>);
        this.tableRef = React.createRef();
        this.take = 10;
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.isRenderedOnAdmin)
            this.getLookupData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    filterData = () => {
        this.skip = 0;
        this.tableRef.current;
        this.tableRef.current.onQueryChange({ page: 0 });
        this.cancelEditing();
    }

    cancelEditing = () => {
        const cancelBtn: any = document.querySelectorAll('[title="Cancel"]')[0];
        if (cancelBtn) { cancelBtn.click(); }
    }

    getLookupData(useCache: boolean = true) {
        this.showLoading(true);
        if (!useCache)
            this.userLookupDataSvc.clearChache();
        this.certLookupDataSvc.clearChache();
        forkJoin(
            // this.userLookupDataSvc.query({ filter: "role eq '20' or role eq '50' or role eq '100'", order: 'firstName asc', oDataQuery: '&$select=Id,FirstName,LastName,Role' }),
            this.certLookupDataSvc.query({ filter: 'isActive eq true', order: 'direction asc, name asc ', oDataQuery: '&$select=id,name,direction' }))
            .subscribe(([certificates]) => {
                // this.setState({ usersLookup: users });
                if (this._isMounted) {
                    this.setState({ certificatesLookup: certificates });
                }
                this.showLoading(false);
            }, (err) => this.handleError(err), () => {
                this.showLoading(false)
            });
    }

    getAvailableCertLookupPerUser(userId: number) {
        this.showLoading(true);
        this.availableCertLookupDataSvc.query({ filter: `userId eq ${userId} and isNextAvailable eq true`, order: 'direction,name asc' })
            .then(res => {
                if (res) {
                    if (this._isMounted) {
                        this.setState({ availableCertificates: res });
                    }
                }
            })
            .catch((err) => this.handleError(err))
            .finally(() => {
                this.showLoading(false);
            });
    }


    getInitialState() {
        //this.user = Auth.getProvider().Identity();
        return {
            columns: [
                {
                    title: 'User', field: 'userId', editable: 'onAdd', hidden: this.user ? this.user.role == UserRole.TestUser : true, customSort: () => 0,
                    initialEditValue: this.user && this.user.role == UserRole.TestUser ? this.user.id : 0,
                    render: rowData => `${rowData.user.firstName} ${rowData.user.lastName}`,
                    // customSort: () => {this.cancelEditing()},
                    editComponent: props => (
                        <AutoComplete
                            value={props.rowData && props.rowData.user ? `${props.rowData.user.firstName} ${props.rowData.user.lastName}` : ''}
                            url="users"
                            filter={"(role eq '20') and (isActive eq true)"}
                            order={"firstName asc"}
                            oDataQuery={"select=Id,FirstName,LastName,Role"}
                            onSelected={(item: User) => props.onChange(item.id)
                            }
                        />
                    )
                },
                {
                    title: 'Certification', field: 'certificateId', editable: 'onAdd', customSort: () => 0,
                    render: rowData => `${rowData.certificate.name} - ${rowData.certificate.direction.toUpperCase()}`,
                    editComponent: props => (
                        <FormControl disabled={!props.rowData.userId}>
                            <Select
                                value={props.value || ''}
                                onChange={(e) => props.onChange(e.target.value)}
                                onOpen={(e) => this.getAvailableCertLookupPerUser(props.rowData.userId)}
                            >{this.state.availableCertificates && this.state.availableCertificates.length > 0
                                ? this.state.availableCertificates.map((el: any, i: number) => <MenuItem key={i} value={el.certificateId}>{el.name} {el.direction}</MenuItem>)
                                : <MenuItem value="" disabled>There are no available certificates for this user</MenuItem>}
                            </Select>
                        </FormControl>)
                },
                {
                    title: 'Supervisor', field: 'supervisorId', initialEditValue: this.user ? this.user.id : 0, customSort: () => 0,
                    render: rowData => rowData.supervisor ? `${rowData.supervisor.firstName} ${rowData.supervisor.lastName}` : '',
                    editComponent: props => (
                        <AutoComplete
                            value={props.rowData && props.rowData.supervisor ? `${props.rowData.supervisor.firstName} ${props.rowData.supervisor.lastName}` : ''}
                            placeholder={"Supervisor name"}
                            url="users"
                            order={"firstName asc"}
                            filter={"(role eq '50' or role eq '100') and (isActive eq true)"}
                            oDataQuery={"select=Id,FirstName,LastName,Role"}
                            onSelected={(item: User) => {
                                if (item.id) {
                                    props.onChange(item.id);
                                } else {
                                    props.onChange(null);
                                }
                            }}
                        />
                    )
                },
                { title: 'Date Enrolled', field: 'enrolledDate', editable: 'never', customSort: () => 0, initialEditValue: null, render: rowData => (rowData && rowData.enrolledDate ? new Date(rowData.enrolledDate).toLocaleDateString() : '-') },
                {
                    title: 'Completion Date Goal', field: 'completionGoalDate', type: 'date', customSort: () => 0, initialEditValue: new Date(), render: rowData => (rowData && rowData.completionGoalDate ? new Date(rowData.completionGoalDate).toLocaleDateString() : '-'),
                    editComponent: props => (
                        <FormControl>
                            <KeyboardDatePicker
                                fullWidth={false}
                                margin="none"
                                autoOk
                                variant="dialog"
                                format="MM/DD/YYYY"
                                value={props.value || ''}
                                allowKeyboardControl={false}
                                onChange={(e) => props.onChange(e)}
                            />
                        </FormControl>
                    )
                },
                { title: 'Completion Date', field: 'completionDate', editable: 'never', customSort: () => 0, initialEditValue: null, render: rowData => (rowData && rowData.completionDate ? new Date(rowData.completionDate).toLocaleDateString() : '-') },
                { title: 'Is Approved', field: 'isApproved', type: 'boolean', hidden: true },
                { title: 'Is Ready For Approval', field: 'isReadyForApproval', type: 'boolean', hidden: true },
                { title: 'Status', field: 'status', editable: 'never', initialEditValue: '-', sorting: false, render: rowData => (rowData.isApproved ? 'Approved' : (rowData.isReadyForApproval ? 'Ready for Approval' : 'In Process')) }
                // { title: 'Status', field: 'status', className: 'sdfsfsdfsddfs', render: rowData => (rowData.isApproved 
                //     ? <div><DoneOutlineSharp className='action-green status-icon'></DoneOutlineSharp>Approved</div> 
                //     : (rowData.isReadyForApproval ? <div><DoneOutlineSharp className='action-grey status-icon'></DoneOutlineSharp>Ready for Approval</div> : <div><Autorenew className='status-icon'/>In Process</div>)), sorting: false }
            ],
            data: [],
            usersLookup: [],
            availableCertificates: [],
            certificatesLookup: [],
            filterByUser: '',
            filterByCertification: 0,
            filterBySupervisor: '',
            oneRecord: false,
        } as ICertEnrolmentsState;
    }

    buildFilter() {
        let filterItems = [];
        filterItems.push(this.state.filterByUser ? this.getFilterStr(this.state.filterByUser, 'User') : null);
        filterItems.push(this.state.filterByCertification ? `certificateId eq ${this.state.filterByCertification}` : null);
        filterItems.push(this.state.filterBySupervisor ? this.getFilterStr(this.state.filterBySupervisor, 'Supervisor') : null);
        filterItems.push(this.state.filterShowReadyForApprovalOnly ? '(isReadyForApproval eq true and isApproved eq false)' : null);

        this.filterStr = filterItems.filter(x => x !== null).join(' and ');
    }

    getFilterStr = (value: string, mainObj: string) => {
        const words = value.split(' ');
        let fName = '';
        words.forEach((w: string) => {
            if (w) {
                if (fName) { fName += ' and '; }
                fName += `contains(${mainObj}/Firstname, '${w}') or contains(${mainObj}/LastName, '${w}')`;
            }
        });
        return `(${fName})`;
    }

    clearFilter() {
        if (this._isMounted) {
            this.setState({ filterByUser: '', filterByCertification: 0, filterBySupervisor: '', filterShowReadyForApprovalOnly: false }, () => {
                this.filterData();
            });
        }
    }

    getOrderFields(fieldName: string) {
        switch (fieldName) {
            case 'userId':
                return 'user/firstName,user/lastname'
            case 'supervisorId':
                return 'supervisor/firstName,supervisor/lastName'
            case 'certificateId':
                return 'certificate/name,certificate/direction'
            default:
                return fieldName;
        }
    }

    approveEnrolment = (enrollment: CertEnrolments) => new Promise((resolve, reject) => {
        this.approveDataSvc.update(enrollment, null, false).then((res) => {
            this.notifySuccess('Enrollment has been successfully approved');
            this.tableRef.current && this.tableRef.current.onQueryChange();
            resolve();
        }).catch(() => {
            this.notifyError('This enrollment could not be approved in this moment.');
            reject()
        })
    });

    //removeStyles() {
    // const children = document.getElementsByTagName('tr') as any;
    // children.map((child:any) => {
    //     child.style.opacity = 1;
    //     child.classList.remove("Mui-selected");
    // });
    // const el = document.getElementsByTagName('tr') as any;
    // el.forEach((x:any) => {

    // });
    // document.getElementsByTagName('tr')[4].style.opacity = 1
    // document.getElementsByTagName('tr')[4].classList.remove("Mui-selected");
    //}

    onError(e: ErrorDetails): boolean {
        if (e.detail && e.detail == 'Invalid object reference.' && e.Type == 'CertItemPoint') {
            this.notifyError('This enrollment already has points.');
            return true;
        }
        return false;
    }

    render() {
        return (
            <div className="admin-table">
                {this.isRenderedOnAdmin ?
                    <Card>
                        <CardContent>
                            <div className="admin-filter-wrapper">
                                <Grid container spacing={3} direction="row" alignItems="center">
                                    <Grid item xl={2} lg={2} sm={4} xs={6}>
                                        <TextField style={{ paddingTop: 5 }} label="User" autoComplete='nope'
                                            value={this.state.filterByUser}
                                            onChange={e => { if (this._isMounted) { this.setState({ filterByUser: e.target.value }) } }} onKeyPress={(e) => { if (e.key === 'Enter') { (this.filterData()) } }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <FilterList />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xl={2} lg={2} sm={4} xs={6}>
                                        <TextField style={{ paddingTop: 5 }} label="Supervisor" autoComplete='nope'
                                            value={this.state.filterBySupervisor}
                                            onChange={e => { if (this._isMounted) { this.setState({ filterBySupervisor: e.target.value }) } }} onKeyPress={(e) => { if (e.key === 'Enter') { (this.filterData()) } }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <FilterList />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xl={2} lg={4} sm={4} xs={6}>
                                        <FormControl >
                                            <InputLabel htmlFor="certificate-filter">Certificate</InputLabel>
                                            <Select className="admin-certificate-role-dl" inputProps={{ name: 'certificate', id: 'certificate-filter', }}
                                                value={this.state.filterByCertification || ''}
                                                onChange={e => { if (this._isMounted) { this.setState({ filterByCertification: e.target.value as number }) } (this.filterData()) }}
                                            >{this.state.certificatesLookup.map((el: Certificate, i: number) => <MenuItem key={i} value={el.id}>{el.name} - {el.direction}</MenuItem>)}
                                            </Select>
                                            {
                                                this.state.filterByCertification ? <IconButton aria-label="delete" className="select-clear-button" size="small" onClick={() => { this.setState({ filterByCertification: 0 }); (this.filterData()) }}>
                                                    <Clear fontSize="inherit" />
                                                </IconButton> : ''
                                            }
                                        </FormControl>
                                    </Grid>
                                    <Grid item xl={3} lg={2} xs={6}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.filterShowReadyForApprovalOnly || false}
                                                    onChange={(e) => { this.setState({ filterShowReadyForApprovalOnly: e.target.checked }); (this.filterData()); }}
                                                    value="checkedB"
                                                    color="primary"
                                                />
                                            }
                                            label="Only Ready for Approval"
                                            classes={{ label: 'filter-checkbox-label' }}
                                        />
                                    </Grid>
                                    <Grid item xl={3} lg={2} sm={6} xs={12} className="admin-filter-buttons">
                                        <Tooltip title="Run Filter" aria-label="runFilter" >
                                            <IconButton aria-label="runFilter" onClick={() => { this.filterData() }}>
                                                <Search />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Clear Filter" aria-label="clearFilter">
                                            <IconButton aria-label="clearFilter" onClick={() => { this.clearFilter() }}>
                                                <Clear />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </div>
                        </CardContent>
                    </Card> : ''
                }
                <div className={`admin-table-material ${this.state.oneRecord ? 'scroll-autocomplete' : ''}`}>
                    <MaterialTable
                        columns={this.state.columns}
                        tableRef={this.tableRef}
                        data={query =>
                            new Promise((resolve, reject) => {
                                this.skip = this.returnFirstPage ? 0 : query.page * query.pageSize;
                                this.returnFirstPage = false;
                                this.take = query.pageSize;
                                this.order = query.orderBy ? `${this.getOrderFields(query.orderBy.field)} ${query.orderDirection}` : 'enrolledDate desc';
                                this.buildFilter();
                                this.oDataQuery = '&$expand=user&$expand=supervisor&$expand=certificate';
                                this.getData().then((res: QueryResult<CertEnrolments>) => {
                                    this.setState({ oneRecord: res.data.length <= 1 })
                                    resolve(res as QueryResult<CertEnrolments>);
                                })
                            })}
                        title="Certification Enrollments"
                        options={{
                            initialPage: (this.skip / this.take || 0),
                            pageSize: this.take,
                            pageSizeOptions: [10, 25, 50],
                            showEmptyDataSourceMessage: true,
                            debounceInterval: 1000,
                            actionsColumnIndex: -1,
                            addRowPosition: "first",
                            search: false,
                            detailPanelType: 'single',
                            actionsCellStyle: { justifyContent: 'flex-end' }
                        }}

                        detailPanel={[
                            {
                                tooltip: 'View Score',
                                render: rowData => {
                                    return (
                                        <CertEnrollmentScore enrollmentId={rowData.id}></CertEnrollmentScore>
                                    )
                                },
                            }]}
                        actions={[
                            (rowData: CertEnrolments) => ({
                                hidden: !rowData.isReadyForApproval || rowData.isApproved || this.user.role == UserRole.TrackerUser || this.user.role == UserRole.GlobalAdmin,
                                icon: 'done_outline',
                                //icon: () => <DoneOutlineSharp className={rowData.isApproved ? 'action-green' : ''}></DoneOutlineSharp>,
                                tooltip: rowData.isReadyForApproval ? 'Approve' : null,
                                onClick: (event, rowData) => {
                                    rowData = rowData as CertEnrolments;
                                    this.approveEnrolment(rowData).then(() => {

                                    });
                                }
                            }),
                            {
                                icon: 'visibility',
                                tooltip: 'Go to Details',
                                onClick: (event, rowData) => {
                                    rowData = rowData as CertEnrolments;
                                    if (this.props.location.pathname.includes('/admin/')) {
                                        this.props.history.push(`/admin/enrollments/${rowData.certificateId}/${rowData.id}`)
                                    }
                                    else {
                                        this.props.history.push(`/enrollment/${rowData.certificateId}/${rowData.id}`)
                                    }
                                },
                            }
                        ]}
                        editable={{
                            onRowAdd: this.isRenderedOnAdmin && this.user.role != UserRole.GlobalAdmin ? (newData) =>
                                new Promise((resolve, reject) => {
                                    newData = this.trimAllStrings(newData);
                                    if (!this.validateModel(newData)) { return reject(); }
                                    setTimeout(() => {
                                        //ToDo: investigate why setState doesn't work without settimeout =>  this.setState({ newItem: newData }, () => { ___ })
                                        if (this._isMounted) {
                                            this.setState({ newItem: newData });
                                        }
                                        this.create()
                                            .then(() => {
                                                this.availableCertLookupDataSvc.clearChache();
                                                this.cleanCache();
                                                resolve();
                                            }).catch(() => reject());
                                    }, 100);
                                }) : null,
                            onRowUpdate: this.isRenderedOnAdmin && this.user.role != UserRole.GlobalAdmin ? (newData, oldData) =>
                                new Promise((resolve, reject) => {
                                    newData = this.trimAllStrings(newData);
                                    if (!this.validateModel(newData)) { return reject(); }
                                    this.update(newData, false)
                                        .then(() => {
                                            this.cleanCache();
                                            resolve();
                                        }).catch(() => reject());
                                }) : null,
                            onRowDelete: this.isRenderedOnAdmin && this.user.role != UserRole.GlobalAdmin ? (item) =>
                                new Promise((resolve, reject) => {
                                    this.delete(item, false)
                                        .then(() => {
                                            //https://github.com/mbrn/material-table/issues/537
                                            this.returnFirstPage = true;
                                            this.cleanCache();
                                            resolve();
                                        }).catch(() => reject());
                                }) : null,
                        }}
                    />
                </div>
            </div>
        )
    }
}