import React, { Component } from 'react';
import { Grid, FormControl, Select, Checkbox, MenuItem } from '@material-ui/core';

type IPointItemWithMultiplierProps = {
    disableCheckbox: boolean;
    disabled: boolean;
    multiplier: number;
    isActive: boolean;
    index: number;
    itemId: number;
    pointTypeId: number;
    onMultiplierChange: any;
    onItemChange: any;
}

export class PointItemWithMultiplier extends Component<IPointItemWithMultiplierProps>{
    constructor(props: IPointItemWithMultiplierProps) {
        super(props);
    }

    getMultiplierOptions = (count: number = 14) => {
        const options = [];
        for (let i = 1; i <= count; i++) { options.push(<MenuItem key={i} value={i}>{i}</MenuItem>) }
        return options;
    }

    render() {
        return (
            <Grid item xs={12}>
                < FormControl variant="standard" disabled={this.props.disabled}>
                    <Select
                        value={this.props.multiplier || 1}
                        onChange={(e: any) => this.props.onMultiplierChange(e.target.value, this.props.itemId, this.props.index, this.props.pointTypeId)}
                        inputProps={{ name: `multiplier-${this.props.index}`, id: `multiplier-id-${this.props.index}` }}>
                        {this.getMultiplierOptions()}
                    </Select>
                </FormControl>
                <Checkbox                
                    disabled={this.props.disabled || this.props.disableCheckbox}
                    checked={this.props.isActive}
                    onChange={(e, checked) => this.props.onItemChange(checked, this.props.itemId, this.props.index, this.props.pointTypeId)}
                    value="checkedB"
                    color="primary"
                />
            </Grid>
        )
    }
};