import React, { FunctionComponent } from 'react';

type ITotalInfoProps = {
    typeName: string,
    possibleValue: number,
    currentValue: number,
    multiplier?: number
}

export const TotalInfo: FunctionComponent<ITotalInfoProps> = ({ typeName, possibleValue, currentValue, multiplier = 1 }) => (
    <div className="section-total-points">
        <div className="section-total-points-header">
            Your {typeName} Point Total
        </div>
        <span> ({possibleValue * multiplier}&nbsp;Possible)  </span>
        <div className="section-total-points-value">
            {currentValue}
        </div>
    </div>
);