import React, { Fragment } from "react";
import { Container, Paper, Grid, Button, Typography, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import "../../App.css";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

import { Auth } from "CORE/utils/Auth";
import { IState, IProps } from "CORE/interfaces/main-interfaces";
import BaseComponent from "CORE/base-classes/BaseComponent";
import { Company } from "../admin/companies.component"


class IResetPassProps extends IProps {
    login: () => void;
    history: any;
    location: any;
}

class ResetPassState extends IState {
    companyId: number;
    email: string;
    passwordReseted: boolean;
}


export default class ResetPassComponent extends BaseComponent<IResetPassProps, ResetPassState> {
    availableCompanies: Company[] = [];

    constructor(props: IResetPassProps) {
        super(props);
    }

    getInitialState() {
        return {
            companyId: 0,
            email: '',
            passwordReseted: false
        }
    }


    componentDidMount() {

    }

    resetPassword = (e: any) => {
        e.preventDefault();
        this.showLoading(true);
        Auth.getProvider().resetPassword(e.target["email"].value as string, this.state.companyId)
            .then(
                (user: any) => {
                    if (user.length > 1) {
                        this.notifyWarning('This user is assigned to multiple companies. Please select company.');
                        user.map((el: any) => {
                            let company = new Company();
                            company.name = el.name;
                            company.id = el.users[0].companyId;
                            this.availableCompanies.push(company);
                        })
                    } else {
                        this.notifySuccess('Please look at your email for further instruction.');
                        this.setState({ passwordReseted: true })
                    }
                }
            ).finally(() => { this.showLoading(false) });
    }


    render() {
        return (
            <div className="login-form">
                <Container maxWidth="sm">
                    <ValidatorForm ref="form" onSubmit={(e: any) => this.resetPassword(e)}>
                        <Paper elevation={3} style={{ padding: 20 }}>
                            <Typography color="textSecondary" variant="h6" component="h4" align="center">
                                Reset Password
                            </Typography>
                            {!this.state.passwordReseted ?
                                <Fragment>
                                    <Typography color="textSecondary">
                                        Enter your account email address and click the reset password button. An email will be sent to you with further instructions.
                                    </Typography>
                                    <TextValidator
                                        style={{ width: '100%' }}
                                        label="Email"
                                        name="email"
                                        type="email"
                                        margin="normal"
                                        value={this.state.email || ""}
                                        onChange={(event: any) => this.setState({ email: event.target.value })}
                                        validators={['required', 'isEmail']}
                                        errorMessages={['this field is required', 'email is not valid']} />
                                    {
                                        this.availableCompanies.length > 0 ?
                                            <Fragment>
                                                <Typography color="textSecondary" className="margin-top-15px">
                                                    This email is assigned to multiple companies. Please select one company for which you want to reset a password.
                                        </Typography>
                                                <FormControl style={{ width: '100%' }} className="MuiFormControl-marginNormal">
                                                    <InputLabel htmlFor="login-companies">Companies</InputLabel>
                                                    <Select className="login-companies"
                                                        value={this.state.companyId || 0}
                                                        onChange={e => { this.setState({ companyId: e.target.value as number }) }}
                                                    >{this.availableCompanies.map((el: Company, i: number) => <MenuItem key={i} value={el.id}>{el.name}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            </Fragment>
                                            : null
                                    }
                                    <Grid
                                        container
                                        direction="row"
                                        justify="flex-end"
                                        alignContent="flex-end"
                                    >
                                        <Button variant="contained" style={{ marginTop: 20, marginRight: 10 }} onClick={() => this.props.history.push("/")}>
                                            Cancel
                                        </Button>
                                        <Button type="submit" variant="contained" color="primary" style={{ marginTop: 20 }}>
                                            Reset Password
                                        </Button>
                                    </Grid>
                                </Fragment> :
                                <Fragment>
                                    <Typography color="textSecondary" className="margin-top-15px">
                                        Please look at your email for further instruction.
                                    </Typography>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="flex-end"
                                        alignContent="flex-end"
                                    >
                                        <Button variant="contained" style={{ marginTop: 20, marginRight: 10 }} onClick={() => this.props.history.push("/login")}>
                                            Back to Login page
                                        </Button>
                                    </Grid>
                                </Fragment>
                            }
                        </Paper>
                    </ValidatorForm>
                </Container>
            </div >
        );
    }
}