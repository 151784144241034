import React, { Component } from 'react';
import { Typography, Slider, IconButton } from '@material-ui/core';
import { PointTypeDescription, CertPoint } from '../module-group';

type ITotalInfoProps = {
    pointType: PointTypeDescription;
    itemId: number;
    points: CertPoint[];
    showButtons: boolean;
    onClickRemove: any;
    onClickAdd: any;
}

export class ProgressControl extends React.Component<ITotalInfoProps>{
    constructor(props: ITotalInfoProps) {
        super(props);
    }

    render() {
        return (<div className="rete-slider" >
            <Typography id="discrete-slider" gutterBottom component={'div'}>
                {this.props.pointType.name}
                <div className="points-value">{this.props.pointType.value} points</div>                
            </Typography>
            <Slider value={this.props.points.filter(x => x.isActive).length} defaultValue={0} getAriaValueText={null} aria-labelledby="discrete-slider" valueLabelDisplay="off" marks step={1} min={0} max={this.props.points.length} />
            {this.props.showButtons ? <div className="btns-rete-slider">
                <IconButton onClick={() => { this.props.onClickRemove(false, this.props.itemId) }} disabled={this.props.points.filter(x => x.isActive).length < 1}>
                    <i className="material-icons">remove</i>
                </IconButton>
                <span className="step-value">  {this.props.points.filter(x => x.isActive).length || 0} / {this.props.points.length} </span>
                <IconButton onClick={() => { this.props.onClickAdd(true, this.props.itemId) }} disabled={this.props.points.filter(x => x.isActive).length >= this.props.points.length}>
                    <i className="material-icons">add</i>
                </IconButton>
            </div> : null}
        </div>);
    }
};