import React from 'react';
import { IProps } from 'CORE/interfaces/main-interfaces';
import { AppBar, Toolbar, Typography, IconButton } from '@material-ui/core';
import { HashRouter as Router, NavLink } from 'react-router-dom';

import { Theme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';

import { User } from 'CORE/entities/User';
import { Auth } from 'CORE/utils/Auth';
import LoadIndicatorService from 'CORE/utils/LoadingService';
import { LoadIndicator } from '../common/LoadIndicator';
import ErrorMessage from '../common/error-message';
import ResourceDataService from 'CORE/data-service/ResourceDataService';
import ErrorResponse from 'CORE/base-classes/Error';
import ChangePassForm from '../change-password-form';
import HeaderMenu from '../common/header-menu';
import { history } from 'CORE/history'


interface IAppState {
    user: User,
    identityInitialized: boolean,
    loading: boolean,
    userInitials: string,
    showChangePass: boolean,
}

class IAppProps extends IProps {
    theme: Theme;
    title: string;
    logo: any;
}


export default class AppWrapper extends React.Component<IAppProps, IAppState> {

    loadingService = LoadIndicatorService.getInstance();

    private userSvc: ResourceDataService<User> = new ResourceDataService('users');

    private provider = Auth.getProvider();

    constructor(props: IAppProps) {
        super(props)

        this.state = {
            user: null,
            identityInitialized: false,
            loading: false,
            userInitials: '',
            showChangePass: false,
        } as IAppState;

        this.loadingService.subscribe((visible: boolean) => this.setState({ loading: visible }));

        this.provider.onInitialized((x: User) => {
            this.setState({ user: x, identityInitialized: true, loading: false });
        });
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.provider.onUnauthorized(this.handleUnauthorized);
        this.provider.getIdentity()
            .then((x: User) => {
                this.setState({ user: x, identityInitialized: true, loading: false });
            });
    }

    logout = () => {
        this.setState({ loading: true });
        this.provider.signOut().then((x: User) => {
            this.setState({ user: null, identityInitialized: true, loading: false });
            //history.push('/');
        })
            .catch((err: Error | ErrorResponse) => {
                history.push('/');
            });
    }

    handleUnauthorized = () => {
        this.setState({ user: null });
        //history.push('/');
    }

    render() {

        return (

            <ThemeProvider theme={this.props.theme}>
                {/* <Router history={history}> */}
                <Router >
                    <div className="app">
                        <AppBar >
                            <Toolbar >
                                <NavLink to={`/`}>
                                    <IconButton id="home-icon" style={{ marginRight: 9, marginLeft: -14 }}><HomeRoundedIcon color="action" style={{ fontSize: 30 }} /></IconButton>
                                </NavLink>
                                <Typography variant="h6" className='title' style={{ paddingLeft: 0 }}>
                                    {this.props.title}
                                </Typography>
                                <img className="logo" src={this.props.logo} />
                                {this.state.user && this.state.user.id ?
                                    <HeaderMenu
                                        user={this.state.user}
                                        onLogOut={this.logout}
                                        onCangePassword={() => this.setState({ showChangePass: true })}
                                    />
                                    :
                                    null
                                }
                            </Toolbar>
                        </AppBar>
                        {
                            this.state.showChangePass ?
                                <ChangePassForm
                                    user={this.state.user}
                                    userUpdated={(user: User) => this.setState({ user, showChangePass: false })}
                                    onCancel={() => this.setState({ showChangePass: false })}
                                />
                                : null
                        }

                        {LoadIndicator(this.state.loading)}
                        {this.state.identityInitialized ? this.props.children : ''}
                        <ErrorMessage />
                    </div>
                </Router>
            </ThemeProvider >

        );
    }
}
